import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, Autocomplete, Box, IconButton, Paper, Select, FormControlLabel } from '@mui/material';
import { sk } from './globals.js';
import { Debug, Spacer, ShowOK, GetDate, Loading, PDFdialog, AskDelete, FakturaSend } from './items.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faCircleCheck, faEdit, faFolderTree, faHome, faPlus, faSearch, faPrint, faXmark, faXmarkCircle, faRotateRight, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme2-6.css";
import { styles } from './styles.js';
import InputAdornment from '@mui/material/InputAdornment';
import Pagination from '@mui/material/Pagination';
import Chip from '@mui/material/Chip';
import Checkbox from '@mui/material/Checkbox';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import CircularProgress from '@mui/material/CircularProgress';
import { Faktura } from './faktura.js';
import { textAlign } from '@mui/system';
import { toHtml } from '@fortawesome/fontawesome-svg-core';

export const Faktury = (props) => {

    const db_list = 'faktury';
    const db_list_update = 'faktury_update';
    const db_list_search = 'faktury_search';

    //const navigate = useNavigate();

    const [isBusy, setBusy] = useState(false);
    const [reload, setReload] = useState(false);
    const [openAcc, setOpenAcc] = useState(-1);
    const [items, setItems] = useState([]);
    const [itemsFiltered, setItemsFiltered] = useState([]);
    const [sum, setSum] = useState(0);
    const [redraw, setRedraw] = useState(false);
    const [showNew, setShowNew] = useState(false);
    const [error, setError] = useState('');
    const [showOK, setShowOK] = useState(false);
    const [showEnabled, setShowEnabled] = useState(false); // zobrazovat len aktívne
    const [years, setYears] = useState([]);
    const [yearID, setYearID] = useState(0);

    // Uprava polozky
    const [editing, setEditing] = useState(false);
    const [selectedItem, setSelectedItem] = useState([]);
    const [selectedID, setSelectedID] = useState(1);

    // MOJE FIRMY
    const [firms, setFirms] = useState([]);
    const [firmID, setFirmID] = useState(-1);
    const [firmSelected, setFirmSelected] = useState([]);

    // SEARCH
    const [search, setSearch] = useState('');
    const [searchArr, setSearchArr] = useState([]);

    // PAGINATION
    const [currentPage, setCurrentPage] = useState(1);
    const [pageMax, setPageMax] = useState(0);

    // PDF
    const [showPDF, setShowPDF] = useState(false);
    const [pdfFilename, setPDFFilename] = useState('');
    const [pdfNumber, setPDFNumber] = useState('');

    // POSLAT FAKTURU
    const [showSend, setShowSend] = useState(false);

    // OPAKOVAT FAKTURU
    const [copy, setCopy] = useState(false);

    // VYMAZAT FAKTURU
    const [showDelete, setShowDelete] = useState(false);


    /*
    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    */

    const boxWidth = 950;
    const boxHeight = 40;

    var lang = sk;

    let params = useParams();

    //const logo = require('./react/app/logo.png');

    useEffect(() => {

        var today = new Date();
        var year = String(today.getFullYear());
        var y = 2020;
        var arr = [];
        while (y <= year) {
            var data = {
                id: y,
                year: y
            }
            arr.push(data);
            y++;
        }
        setYearID(year);
        setYears(arr);

        db_odberatelia();
        //db_items(0, [], false, -1, year);

        return () => {
        };

    }, []);

    const db_odberatelia = async () => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'my_firms', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    typ: props.user_typ,
                    enabled: false,
                    arr: [],
                    start: 0,
                    count: 999999
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.items;
                setFirms(tmp);
            }
            setBusy(false);

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }

    const db_items = async (start, arr, enabled_, firm_id, year) => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + db_list, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    typ: props.user_typ,
                    enabled: enabled_,
                    arr: arr,
                    start, start,
                    count: global.items_max,
                    firm_id: firm_id,
                    year: year
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                var sume = 0.00;
                tmp.forEach(item => {
                    sume = sume + parseFloat(item.sum);
                });
                setSum(sume.toFixed(2));

                // pagination
                var tmp = json.count;
                tmp = Math.ceil(tmp / global.items_max);
                setPageMax(tmp);
            }
            setBusy(false);

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }

    const db_delete = async (item) => {

        if (isBusy == false) {
            setBusy(true);

            try {
                const response = await fetch(
                    global.db_url + 'faktura_delete', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        id: item.id
                    })
                })

                const json = await response.json();

                if (json.ok > 0) {
                    db_items(0, searchArr, showEnabled, firmID, yearID);
                    setOpenAcc(-1);
                }
                setBusy(false);

            } catch (error) {
                Debug(error);
                setBusy(false);
            }
        }
    }

    const db_faktura_print = async (faktura) => {
        if (isBusy == false) {
            setBusy(true);

            try {
                const response = await fetch(
                    global.db_url + 'faktura_print', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        id: faktura.id
                    })
                })

                const json = await response.json();

                if (json.ok > 0) {
                    setPDFFilename(json.filename);
                    setShowPDF(true);
                    setPDFNumber(faktura.number);
                }
                setBusy(false);

            } catch (error) {
                Debug(error);
                setBusy(false);
            }
        }
    }

    const db_uhrada = async (id, status) => {
        if (isBusy == false) {
            setBusy(true);

            try {
                const response = await fetch(
                    global.db_url + 'faktura_uhrada', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        id: id,
                        status: status
                    })
                })

                const json = await response.json();

                if (json.ok > 0) {
                }
                setBusy(false);

            } catch (error) {
                Debug(error);
                setBusy(false);
            }
        }
    }


    const AddNew = () => {
        // Nova faktura
        setEditing(false);
        setCopy(false);
        setShowNew(true);
    }

    const Edit = (item) => {
        // uprava faktury
        setEditing(true);
        setCopy(false);
        setSelectedItem(item);
        setSelectedID(item.id);
        setShowNew(true);
    }


    const Copy = (item) => {
        // opakovat fakturu
        setEditing(true);
        setCopy(true);
        setSelectedItem(item);
        setSelectedID(item.id);
        setShowNew(true);
    }

    const Delete = (item) => {
        // vymazat fakturu
        setSelectedItem(item);
        setShowDelete(true);
    }

    const FakturaResult = (typ, data) => {
        setError('');

        if (typ == 0) {
            // ZAVRIET
            setOpenAcc(-1);
        }
        if (typ == 1) {
            // ULOZIT ZMENY POLOZKY

            var tmp = itemsFiltered.filter(x => x.id == data.id);
            if (tmp.length > 0) {
                //tmp[0] = { ...data };
                tmp[0].company = data.company;
                tmp[0].street = data.street;
                tmp[0].street_number = data.street_number;
                tmp[0].psc = data.psc;
                tmp[0].town = data.town;
                tmp[0].country = data.country;
                tmp[0].ico = data.ico;
                tmp[0].dic = data.dic;
                tmp[0].ic_dph = data.ic_dph;
                tmp[0].dph = data.dph;
                tmp[0].date_register = data.date_register;
                tmp[0].sum = data.sum;
                tmp[0].number = data.number;
                tmp[0].note = data.note;
                tmp[0].datum = GetDate(data.date_register);
                setReload(!reload);

            }
        }
        if (typ == 2) {
            // NOVY ZAZNAM      
            db_items(0, searchArr, showEnabled, firmID, yearID);
        }
        setRedraw(!redraw);
        setShowNew(false);
    }

    const SetOpenAcc = (id) => {
        if (openAcc == id) {
            setOpenAcc(-1);
        } else {
            setOpenAcc(id);
        }
    }

    const PageChange = (event, value) => {
        // PAGINATION
        setCurrentPage(value);
        var page = (value - 1) * global.items_max;
        db_items(page, searchArr, showEnabled, firmID, yearID);
    };


    const ShowOKPress = (value) => {
        setShowOK(false);
    }

    const ItemsSearchResult = (arr, search_) => {

        if (arr != null) {
            if (arr.length > 0) {
                setSearchArr(arr);
                setCurrentPage(1);
                db_items(0, arr, false, firmID, yearID);
                setSearch(search_);
            } else {
                setSearchArr(arr);
                setCurrentPage(1);
                setSearch(search_);
                setItems([]);
                setItemsFiltered([]);
            }
        } else {
            setSearch(search_);
            setItemsFiltered([]);
        }
    }

    const SearchReset = () => {
        setSearchArr([]);
        setCurrentPage(1);
        db_items(0, [], showEnabled, firmID, yearID);
        setSearch('');
    }

    const SetShowEnabled = () => {
        var value = !showEnabled;
        setShowEnabled(value);
        db_items(0, searchArr, value, firmID, yearID);
    }

    const Reload = () => {
        setCurrentPage(0);
        db_items(0, searchArr, showEnabled, firmID, yearID);
    }

    const firmChange = (event) => {
        var value = parseInt(event.target.value);
        setFirmID(value);
        if (value >= 0) {
            var tmp = firms.find(x => x.id == value);
            setFirmSelected(tmp);
        }
        setSearch('');
        setSearchArr([]);
        setOpenAcc(-1);
        db_items(0, [], false, value, yearID);
    }

    const yearChange = (event) => {
        var value = parseInt(event.target.value);
        setYearID(value);
        db_items(0, [], false, firmID, value);
        setSearch('');
        setSearchArr([]);
    }

    const Print = (item) => {
        db_faktura_print(item);
    }

    const PDFdialogResult = () => {
        setShowPDF(false);
    }

    const AskDeleteResult = (value) => {
        setShowDelete(false);
        if (value == true) {
            db_delete(selectedItem);
        }
    }

    const Send = (item) => {
        setSelectedItem(item);
        setShowSend(true);
    }

    const FakturaSendResult = (result) => {
        setShowSend(false);
    }

    const Uhradit = (item) => {
        var id = item.id;
        var status = item.uhradena == 0 ? true : false;

        var tmp = items.find(x => x.id == id);
        tmp.uhradena = status;

        var tmp = itemsFiltered.find(x => x.id == id);
        tmp.uhradena = status;

        db_uhrada(id, status);
        setRedraw(!redraw);

    }

    /* *****************************************************************
        HLADANIE
    ***************************************************************** */
    const ItemsSearch = (props) => {
        /*
                V PHP nastaviť keys
        */

        const [search, setSearch] = React.useState('');
        const [isBusy, setBusy] = React.useState(false);
        const [items, setItems] = React.useState([]);
        const [err, setErr] = React.useState('');

        let { func } = props;
        var lang = sk;

        useEffect(() => {

            return () => {
            };

        }, []);

        const Press = (items_) => {
            func(items_, search);
        }

        const db_search = async (type, txt) => {
            // SEARCH DURING TYPING - type = 0
            // SEARCH AFTER ENTER - type 1 / 2

            setBusy(true);
            var search_ = txt.trim();
            try {
                const response = await fetch(
                    global.db_url + db_list_search, {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        firm_id: props.firm_id,
                        typ: type,
                        search: search_,
                        year: props.year
                    })
                })

                const json = await response.json();

                setBusy(false);
                if (json.ok > 0) {
                    setItems(json.items);
                    if (type > 0) {
                        // vrátiť nájdené položky
                        Press(json.items);
                    }
                }
            } catch (error) {
                Debug(error);
                setBusy(false);
            }

        }


        const SearchProduct = (value) => {
            // PO STLACENI ENTER        
            // zobrazenie vybratého produktu

            if (value.id == undefined) {
                // jedna sa o hladany výraz
                if (search.length > 2) {
                    // zobraziť výsledok
                    db_search(1, value);
                }
            } else {
                // bol označený konkrétny produkt            
                db_search(2, value.id);
            }
        }

        const Search = (search_) => {
            // HLADANIE POCAS PISANIE
            setSearch(search_);
            if (search_.length > 2) {
                setErr('');
                db_search(0, search_);
            }
        }

        return (

            <div style={{ ...styles.Block, justifyContent: 'flex-end', alignItems: 'center' }}>
                <Autocomplete
                    freeSolo
                    disableClearable
                    style={{ width: '100%', maxWidth: 600, backgroundColor: global.theme_gray }}
                    options={items}
                    getOptionLabel={(option) => option.keys ? option.keys : ""}
                    loading={isBusy}
                    size={'small'}
                    onChange={(event, newValue) => { SearchProduct(newValue); }}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                        }
                    }}
                    renderOption={(props, option) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.id}>
                            {/*
                            <div style={{ ...styles.Block, width: 50, marginRight: 10 }}>
                                <img
                                    loading="lazy"
                                    style={{ maxWidth: 50, maxHeight: 50 }}
                                    src={global.product_image + option.path + '/th_' + option.image}
                                    alt=""
                                />
                            </div>
                            */}
                            <p style={{ ...styles.TextTiny }}>
                                {option.keys}
                            </p>
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={err == '' ? lang.search : err}
                            onInput={e => Search(e.target.value)}
                            error={err == '' ? false : true}
                            style={{ backgroundColor: global.theme_white }}
                            InputProps={{
                                ...params.InputProps,
                                type: 'search',
                                startAdornment: <InputAdornment position="start"><FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_black }} icon={faSearch} /></InputAdornment>,
                                endAdornment: (
                                    <React.Fragment>
                                        {isBusy ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            </div>
        )

    }


    /* *****************************************************************

        RENDER - HLAVNE OKNO

    ***************************************************************** */
    return (
        <div style={{ ...styles.BlockCenter, height: '100%', marginTop: 20 }}>

            {/* PRIDAŤ NOVU */}
            {showNew == true ?
                <div style={{ ...styles.Block, maxWidth: boxWidth, }}>
                    <Faktura copy={copy} firm={firmSelected} firms={firms} user_typ={props.user_typ} editing={editing} item={selectedItem} func={FakturaResult.bind(this)} />
                    {error != '' ?
                        <div style={{ marginBottom: 10 }}>
                            <p style={{ ...styles.TextTiny, fontWeight: 'bold', color: global.theme_red }}>{error}</p>
                        </div>
                        : null}
                </div>
                :

                <Paper elevation={3} style={{ ...styles.Block, maxWidth: boxWidth, backgroundColor: global.theme_white, border: '1px solid ' + global.theme_light_gray }}>
                    {/* TOP MENU - HLADAT */}
                    {showNew == false ?
                        <div style={{ ...styles.BlockRow, width: '95%', marginTop: 20, marginBottom: 20 }}>

                            {/* SEARCH */}
                            <div style={{ ...styles.BlockLeft, width: '40%' }}>
                                <ItemsSearch year={yearID} firm_id={firmID} func={ItemsSearchResult.bind(this)} />
                            </div>

                            {/* ROKY */}
                            <div style={{ ...styles.Block, width: '20%', marginLeft: 10 }}>
                                <Select
                                    color="primary"
                                    value={yearID}
                                    label={''}
                                    onChange={yearChange}
                                    style={{ width: '100%', textAlign: 'left' }}
                                    size={'small'}
                                >
                                    {years.map(item => (
                                        <MenuItem key={item.id} value={item.id}>{item.year}</MenuItem >
                                    ))}
                                </Select>
                            </div>

                            {/* MOJE FIRMY */}
                            <div style={{ ...styles.Block, width: '40%', marginLeft: 10 }}>
                                <Select
                                    color="primary"
                                    value={firmID}
                                    label={''}
                                    onChange={firmChange}
                                    style={{ width: '100%', textAlign: 'left' }}
                                    size={'small'}
                                >
                                    <MenuItem key={-1} value={-1}>Všetky</MenuItem >
                                    {firms.map(item => (
                                        <MenuItem key={item.id} value={item.id}>{item.company}</MenuItem >
                                    ))}
                                </Select>
                            </div>

                            <div style={{ ...styles.BlockRight, flexDirection: 'row', width: 180 }}>
                                {firmID == -1 ? null :
                                    <Button onClick={() => AddNew()} style={{ ...styles.ButtonRaw }}>
                                        <p style={{ ...styles.TextSmall, marginRight: 10 }}>{lang.faktura_new}</p>
                                        <div style={{ ...styles.BlockRound, backgroundColor: global.theme_red }}>
                                            <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_white }} icon={faPlus} />
                                        </div>
                                    </Button>
                                }
                            </div>
                        </div>
                        : null}

                    {/* SEARCHED - TEXT */}
                    {
                        search != '' && showNew == false ?
                            <div style={{ ...styles.BlockLeft, width: '95%', marginBottom: 20 }}>
                                <div style={{ ...styles.BlockLeft, flexDirection: 'row' }}>
                                    <p style={{ ...styles.TextSmall, marginRight: 20, marginTop: 6 }}>{lang.search_text}:</p>
                                    <Chip label={search} onDelete={SearchReset} />
                                </div>
                            </div>
                            : null
                    }

                    {/* PRIDAŤ NOVU */}
                    {showNew == true ? null :
                        <div style={{ ...styles.Block, width: '95%', paddingTop: 20, paddingBottom: 20 }}>

                            {/* ZOZNAM */}
                            {itemsFiltered.length > 0 ?
                                <div style={{ ...styles.BlockLeft }}>
                                    <p style={{ ...styles.TextTiny, marginBottom: 20 }}>{lang.items_list}</p>
                                </div>
                                :
                                search != '' ?
                                    <div style={{ ...styles.Block }}>
                                        <p style={{ ...styles.TextSmall, marginBottom: 20, marginTop: 20, fontWeight: 'bold' }}>{lang.items_list_search_none}</p>
                                    </div>
                                    :
                                    <div style={{ ...styles.Block }}>
                                        <p style={{ ...styles.TextSmall, marginBottom: 20, marginTop: 20 }}>{lang.items_list_empty}</p>
                                    </div>

                            }

                            {firmID > -1 ?
                                <div style={{ ...styles.Block, paddingTop: 10, paddingBottom: 40 }}>
                                    <div style={{ ...styles.BlockLeft, width: '100%' }}>
                                        <p style={{ ...styles.TextLarge }}>Suma za rok: {sum} {lang.money}</p>
                                    </div>
                                </div>
                                : null}
                            {
                                /*
                                    ZOZNAM POLOZIEK
                                */
                                itemsFiltered.map(item => (
                                    <Accordion key={item.id} elevation={0} expanded={openAcc == item.id ? true : false} onChange={() => SetOpenAcc(item.id)} style={{ width: '100%', backgroundColor: global.theme_gray, border: item.id == selectedID ? '1px solid ' + global.theme_blue : null }}>
                                        <AccordionSummary expandIcon={<FontAwesomeIcon style={{ width: 14, height: 14, color: global.theme_black }} icon={faChevronUp} />}>
                                            <div style={{ ...styles.Block, width: 40, height: boxHeight }}>
                                                <IconButton onClick={() => Delete(item)} style={{ ...styles.BlockRound, backgroundColor: global.theme_gray, width: 36, height: 36 }}>
                                                    <FontAwesomeIcon style={{ width: 14, height: 14, color: global.theme_medium_gray }} icon={faXmark} />
                                                </IconButton>
                                            </div>
                                            <div style={{ ...styles.BlockLeft, justifyContent: 'center', alignItems: 'flex-start', width: 140, height: boxHeight }}>
                                                <p style={{ ...styles.TextTiny, color: global.theme_black, marginLeft: 10 }}>{item.number}</p>
                                                {item.uhradena == false ?
                                                    <p style={{ ...styles.TextTiny, color: global.theme_red, marginLeft: 10 }}>NEUHRADENÁ</p>
                                                    : null}
                                            </div>
                                            <div style={{ ...styles.Block, width: 120, height: boxHeight }}>
                                                <p style={{ ...styles.TextTiny, color: global.theme_black, marginLeft: 10 }}>{item.datum}</p>
                                            </div>
                                            <div style={{ ...styles.Block, alignItems: 'flex-start', width: '70%', height: boxHeight }}>
                                                <p style={{ ...styles.TextTiny, color: global.theme_black, marginLeft: 10 }}>{item.company} - {item.town}</p>
                                                {firmID == -1 ?
                                                    <p style={{ ...styles.TextXXTiny, color: global.theme_medium_gray, marginLeft: 10 }}>{item.firm}</p>
                                                    : null}
                                            </div>
                                            <div style={{ ...styles.Block, alignItems: 'flex-end', width: 120, height: boxHeight }}>
                                                <p style={{ ...styles.TextTiny, color: global.theme_black, marginRight: 10 }}>{item.sum} €</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: 60, height: boxHeight }}>
                                                <IconButton onClick={() => Edit(item)} style={{ ...styles.BlockRound, backgroundColor: global.theme_lighter }}>
                                                    <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_black }} icon={faEdit} />
                                                </IconButton>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails style={{ backgroundColor: global.theme_grayer }}>
                                            <div style={{ ...styles.BlockRow, paddingTop: 10, paddingBottom: 10 }}>
                                                <div style={{ ...styles.BlockLeft }}>
                                                    <p style={{ ...styles.TextTiny }}>Faktúra <b>{item.number}</b></p>
                                                    <p style={{ ...styles.TextNormal, fontWeight: 'lighter', marginTop: 10 }}>{'Firemné údaje:'}</p>
                                                    <div style={{ ...styles.BlockLeft, paddingTop: 20, marginLeft: 20 }}>
                                                        <p style={{ ...styles.TextTiny, marginTop: 0 }}>názov: <b>{item.company}</b></p>
                                                        <p style={{ ...styles.TextTiny, marginTop: 3 }}>adresa: {item.street} {item.street_number}, {item.psc} {item.town}, {item.country}</p>
                                                        <p style={{ ...styles.TextTiny, marginTop: 20 }}>IČO: {item.ico}</p>
                                                        <p style={{ ...styles.TextTiny, marginTop: 3 }}>DIČ: {item.dic}</p>
                                                        {item.typ == 1 ?
                                                            <p style={{ ...styles.TextTiny, marginTop: 3 }}>IČ DPH: {item.ic_dph}</p>
                                                            : null}
                                                        <p style={{ ...styles.TextTiny, marginTop: 3 }}>Typ platiteľa: {global.dph[item.dph]}</p>
                                                        {item.note != '' ?
                                                            <p style={{ ...styles.TextTiny, marginTop: 20 }}><b>Poznámka:</b> {item.note}</p>
                                                            : null}
                                                    </div>
                                                </div>
                                                <div style={{ ...styles.BlockLeft }}>
                                                    <p style={{ ...styles.TextNormal, fontWeight: 'lighter', marginTop: 10 }}>{'Úhrada faktúry'}</p>
                                                    <FormControlLabel
                                                        style={{ textAlign: 'left', margin: 0, marginTop: 20, padding: 0, fontSize: global.font_tiny }}
                                                        label={lang.faktura_uhrada}
                                                        control={<Checkbox checked={item.uhradena == 0 ? false : true} size={'small'} onChange={() => Uhradit(item)} />}
                                                    />
                                                </div>
                                            </div>
                                            <div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 10 }}>
                                                <div style={{ ...styles.BlockRowRaw }}>
                                                    <Button onClick={() => Print(item)} style={{ ...styles.ButtonBack, color: global.theme_white, textTransform: 'none', marginRight: 10 }}>
                                                        <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_white, marginRight: 10 }} icon={faPrint} />
                                                        <p style={{ ...styles.TextTiny, color: global.theme_whire, fontWeight: 'normal' }}>Tlačiť</p>
                                                    </Button>
                                                    {item.email != '' ?
                                                        <Button onClick={() => Send(item)} style={{ ...styles.ButtonBack, color: global.theme_white, textTransform: 'none', marginLeft: 10, marginRight: 10 }}>
                                                            <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_white, marginRight: 10 }} icon={faPaperPlane} />
                                                            <p style={{ ...styles.TextTiny, color: global.theme_whire, fontWeight: 'normal' }}>Odoslať</p>
                                                        </Button>
                                                        : null}
                                                    <Button onClick={() => Copy(item)} style={{ ...styles.ButtonBack, textTransform: 'none', marginLeft: 10 }}>
                                                        <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_white, marginRight: 10 }} icon={faRotateRight} />
                                                        <p style={{ ...styles.TextTiny, color: global.theme_white, fontWeight: 'normal' }}>Opakovať</p>
                                                    </Button>
                                                </div>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                ))
                            }
                            {pageMax > 1 ?
                                <div style={{ ...styles.Block, maxWidth: 900, paddingTop: 10, marginTop: 20, paddingBottom: 20, borderTop: '1px solid ' + global.theme_light_gray }}>
                                    <Pagination count={pageMax} size={'medium'} page={currentPage} onChange={PageChange} showFirstButton showLastButton />
                                </div>
                                : null}

                            <div style={{ ...styles.BlockRow, paddingTop: 40 }}>
                                <div style={{ ...styles.BlockLeft }}>
                                    {/*
                                    <FormControlLabel
                                        style={{ textAlign: 'left', margin: 0, marginTop: 20, padding: 0, fontSize: global.font_tiny }}
                                        label={lang.item_show_enabled}
                                        control={<Checkbox checked={showEnabled} size={'small'} onChange={() => SetShowEnabled()} />}
                                    />
                                    */}
                                </div>
                                <div style={{ ...styles.BlockRight, width: 60 }}>
                                    <IconButton onClick={() => Reload()} style={{ ...styles.BlockRound, backgroundColor: global.theme_white, marginTop: 20 }}>
                                        <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_medium_gray }} icon={faRotateRight} />
                                    </IconButton>
                                </div>
                            </div>

                        </div>


                    }
                </Paper >
            }
            <Spacer height={50} />

            {showPDF == true ?
                <PDFdialog label={'Faktúra: ' + pdfNumber} filename={pdfFilename} func={PDFdialogResult.bind(this)} />
                : null}

            {showDelete == true ?
                <AskDelete label='Vamazať' question='Chcete vymazať danú faktúru?' text='' func={AskDeleteResult.bind(this)} />
                : null}

            {showSend == true ?
                <FakturaSend typ={props.user_typ} item={selectedItem} func={FakturaSendResult.bind(this)} />
                : null}

            {showOK == true ?
                <ShowOK func={ShowOKPress.bind(this)} />
                : null}

            {isBusy == true ?
                < Loading />
                : null}

        </div >
    )
};

