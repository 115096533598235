import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, Alert, Select, IconButton, Paper } from '@mui/material';
import { sk } from './globals.js';
import { Debug, LoadLogin, LoadUser, MenuTop, SaveUser, SaveLogin, ValidateEmail } from './items.js';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleUp, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { InputLabel } from '@mui/material';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme2-6.css";
import { styles } from './styles.js';
import { ForgotPasswordDialog } from './forgot_password.js';
import CircularProgress from '@mui/material/CircularProgress';
import ReCAPTCHA from 'react-google-recaptcha'


export const Register = (props) => {
    const navigate = useNavigate();
    const [isBusy, setBusy] = useState(false);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [usernameErr, setUsernameErr] = useState('');
    const [passwordErr, setPasswordErr] = useState('');
    const [error, setError] = useState('');

    const [recaptcha, setRecaptcha] = useState('');


    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    var language = 0;
    var lang = sk;

    let params = useParams();

    const homeRef = useRef(null)

    const logo = require('./react/app/logo_white.png');
    const logo_color = require('./react/app/logo.png');

    useEffect(() => {

        return () => {
        };

    }, []);



    const db_register = async () => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'register', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username: username,
                    password: password,
                })
            })

            const json = await response.json();
            
            if (json.ok > 0) {
                if (json.user != null) {
                    setError('');
                    var today = new Date();
                    var data = { logged: true, date: today };
                    SaveLogin(data);

                    var user = json.user;
                    SaveUser(user);

                    navigate('/main');
                }
            } else {
                if (json.count > 0) {
                    // UCET UZ EXISTUJE
                    setError(lang.password_exists);
                } else {
                    setError(lang.login_error);
                }
            }
            setBusy(false);

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }

    const LoginProccess = () => {
        var error = '';
        setError('');
        setUsernameErr('');
        setPasswordErr('');
        if (username.trim() == '') {
            setUsernameErr(lang.required);
            error = lang.required;
        } else {
            if (ValidateEmail(username) == false) {
                setUsernameErr(lang.email_error);
                error = lang.email_error;
            }
        }
        if (password.trim() == '') {
            setPasswordErr(lang.required);
            error = lang.required;
        } else {
            if (password.length < 8) {
                setPasswordErr(lang.password_min_len);
            }
        }
        if (recaptcha == '') {
            error = lang.captcha_error;
        }

        if (error == '') {
            db_register();
        } else {
            setError(error);
        }
    }

    const onChangeCaptcha = (value) => {
        if (value == null) {
            setRecaptcha('');
        } else {
            setRecaptcha(value);
        }
    }

    return (
        <div style={{ ...styles.Block, backgroundColor: global.theme_white, height: isSmall ? undefined : '100vh', minHeight: isSmall ? undefined : '100vh' }}>
            <div style={{ ...styles.Block, flexDirection: isSmall ? 'column' : 'row', backgroundColor: global.theme_dark, height: isSmall ? undefined : '100vh', }}>
                <div style={{ ...styles.Block, width: isSmall ? '100%' : '40%', height: isSmall ? undefined : '100vh', paddingBottom: isSmall ? 20 : undefined }}>
                    <div style={{ ...styles.Block, height: 100 }}>
                        <p style={{ ...styles.TextXXLarge, marginTop: 40, color: global.theme_gray }}>{lang.title.toUpperCase()}</p>
                    </div>
                    <div style={{ ...styles.Block, alignItems: 'center', justifyContent: 'center', height: isSmall ? undefined : '70vh' }}>
                        <img src={global.images + 'app/banner_register.jpg'} style={{ minWidth: '80%', width: '80%' }} />
                        <p style={{ ...styles.TextSmall, marginTop: 10, color: global.theme_light_gray }}>{global.www}</p>
                    </div>
                </div>
                <div style={{ ...styles.Block, width: isSmall ? '100%' : '60%', backgroundColor: global.theme_white, height: isSmall ? undefined : '100vh' }}>

                    <Paper elevation={3} variant={'elevation'} style={{ backgroundColor: global.theme_gray, borderRadius: 20, width: 320, padding: 20 }}>
                        <div style={{ width: '100%', padding: 0 }}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                                    <p style={{ ...styles.TextXXLarge }}>{lang.register}</p>
                                </div>
                            </div>
                            <div style={{ ...styles.Block, marginTop: 25, marginBottom: 25 }}>
                                <img src={global.images + 'app/logo.png'} width={120} />
                            </div>
                            <div style={{ padding: 0, marginTop: 5 }}>
                                <div style={{ padding: 10 }} >
                                    <TextField error={usernameErr == '' ? false : true} helperText={usernameErr} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={lang.email} variant="outlined" value={username} onInput={e => setUsername(e.target.value)} />
                                </div>
                                <div style={{ padding: 10 }} >
                                    <TextField error={passwordErr == '' ? false : true} helperText={passwordErr} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={lang.password_} variant="outlined" type="password" value={password} onInput={e => setPassword(e.target.value)} />
                                </div>
                            </div>
                            <div style={{ ...styles.Block, marginTop: 20 }}>
                                <ReCAPTCHA
                                    sitekey="6LcBgIQeAAAAAMk_qe_tHfZjpY0vOuhtfxP1RtgP"
                                    onChange={onChangeCaptcha}
                                />
                            </div>
                            <div style={{ height: 50, marginTop: 10 }}>
                                {isBusy == true ?
                                    <CircularProgress color="inherit" size={20} />
                                    : error != '' ?
                                        <Alert severity="error">
                                            {error}
                                        </Alert>
                                        : null
                                }
                            </div>
                            <div style={{ ...styles.Block, marginTop: 20 }}>
                                <Button onClick={() => LoginProccess()} style={{ ...styles.ButtonGreen }} variant="contained">{lang.register_button}</Button>
                                <Button href={global.web} style={{ ...styles.ButtonLink, marginTop: 20 }} variant="text">{lang.goto_home}</Button>
                            </div>
                        </div>
                    </Paper>

                </div>
            </div>

        </div >
    )
};

