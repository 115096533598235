/*
*
* =================================================================================================================
* DATABASE name -> xt034401db
* =================================================================================================================
*
*/


import { faBookOpen, faCogs } from "@fortawesome/free-solid-svg-icons";

global.testing = false;

// **********************************************************************
// DATABAZA - LINK
// **********************************************************************
global.web = 'https://app.datadream.sk';
global.db_url = global.web + '/app_admin/';
global.images = global.web + '/public/images/';
global.pdf = global.web + '/public/pdf/';
global.excel = global.web + '/public/excel/';
global.logo = global.web + '/public/images/logo/';
global.stamp = global.web + '/public/images/stamp/';
global.www = 'www.datadream.sk';

global.local_user = 'app_datadream_user';
global.local_login = 'app_datadream_login';
global.local_unique_id = 'app_datadream_uid';
global.local_settings = 'app_datadream_settings';

// **********************************************************************

global.debugging = global.testing;
global.version = '1.0';
global.items_max = 20; // pagination - max.items na stránke
global.tabsize = 40;

// **********************************************************************
// COLORS
// **********************************************************************
global.theme_back = '#F4F5FA';
global.theme_white = '#ffffff';
global.theme_black = '#000000';
global.theme_black_opacity = '#000000bb';
global.theme_light = '#8957F4';
global.theme_medium = '#E3E5F0';
global.theme_light_blue = '#509DF4';
global.theme_lighter_blue = '#ADCCEF';
global.theme_light_red = '#FFDDDD';
global.theme_light_green = '#C0E0B8';
global.theme_lighter = '#C3CDDA';
global.theme_dark = '#28243B';
global.theme_darker = '#252234';
global.theme_dark_blue = '#16406F';
global.theme_dark_red = '#F44336';
global.theme_dark_green = '#4D7B4D';
global.theme_blue = '#51AFF5';
global.theme_red = '#F25751';
global.theme_green = '#76C73B';
global.theme_yellow = '#F4B549';
global.theme_badge = '#A12E28';
global.theme_placeholder = '#cccccc';
global.theme_light_violet = '#FFAAFF';
global.theme_none = '#00000000';

global.theme_gray = '#eeeeee';
global.theme_grayer = '#f7f7f7';
global.theme_xxgray = '#aaaaaa';
global.theme_dark_gray = '#666666';
global.theme_medium_gray = '#888888';
global.theme_light_gray = '#dedede';
global.theme_menu = '#000000';
global.theme_menu_selected = '#aa0000';
global.theme_menu_background_selected = '#ffeeee';
global.theme_submenu = '#aaaaaa';
global.theme_categorymenu = '#ffffff';

global.theme_selected = '#4D617B';
global.theme_orange = 'orange';
global.theme_violet = 'violet';
global.theme_yellow = 'yellow';
global.theme_brown = 'brown';

global.theme_button = '#36666E';
global.theme_button_buy = '#F44336';
global.theme_button_link = '#209FB6';
global.theme_box_label = '#222222';
global.theme_box_label_over = '#AA3333';

global.theme_menu_categories = '#F44336';
global.theme_new_products = '#f0f0f0';

global.theme_footer_text = '#AAAAAA';
global.theme_footer_text_high = '#CCCCCC';
global.theme_footer = '#222222';

global.theme_product_new = '#4D7B4D';
global.theme_logo_background = '#B6B6E9';
global.theme_alert = '#AA0000';

global.theme_gradient = 'linear-gradient(to right,#8957F4,#5114CE)';


// **********************************************************************
// DISPLAY SIZE
// **********************************************************************
global.device_small = 600;
global.device_medium = 992;
global.screen_max = 950;

// **********************************************************************
// FONT SIZE
// **********************************************************************
global.font_xxtiny = 12;
global.font_tiny = 14;
global.font_small = 16;
global.font_normal = 18;
global.font_large = 20;
global.font_xlarge = 24;
global.font_xxlarge = 28;
global.font_label = 28;
global.font_dialog_label = 24; // dialogove boxy - label
global.font_menu = 18; // menu - lava strana
global.font_textlabel = 13; // label pre TextField <p></p>
global.font_categorymenu = 14;
global.font_products_grid = 16;
global.font_banner_label = 36;
global.font_banner_text = 18;
global.font_banner_label_small = 18;
global.font_banner_text_small = 14;
global.font_prices = 22;
global.font_blog = 20;
// **********************************************************************
// RADIUS
// **********************************************************************
global.radius = 5;

// **********************************************************************
// MENU INDEX
// **********************************************************************
global.menu_padding = 40;
global.menu_button_width = 130;

global.menu_main = 0;
global.menu_width = 280;

global.menu_action = 1004;
global.menu_subaction = 9377;

global.creator = 'Datadream s.r.o.';
global.creator_web = 'https://datadream.sk';

// **********************************************************************
// IMAGE TYPS - upload image
// **********************************************************************
global.image_logo = 0;
global.image_stamp = 1;


// **********************************************************************
// TYP PLATITELA
// **********************************************************************
global.dph = [
    'Neplatiteľ DPH', 'Platiteľ DPH', 'Registrovaný podľa §7, 7a'
]

// **********************************************************************
// SPOSOB PLATBY
// **********************************************************************
global.payment = [
    'Bankový prevod', 'Hotovosť', 'Dobierka'
]

// **********************************************************************
// STRUKTURA MENU
// **********************************************************************
global.menuitems = [
    {
        id: '1',
        enabled: true,
        name: 'Fakturácia',
        label: 'Fakturácia',
        icon: faBookOpen,
        children: [
            {
                id: '1',
                enabled: true,
                name: 'Faktúry',
                label: 'Zoznam faktúr',
            },
            {
                id: '2',
                enabled: true,
                name: 'Odberatelia',
                label: 'Zoznam odberateľských firiem',
            },
            {
                id: '3',
                enabled: true,
                name: 'Firemné údaje',
                label: 'Zoznam naších firiem a nastavení',
            },
        ],
    },
    /*
    {
        id: '2',
        enabled: true,
        name: 'Nastavenia',
        label: 'Nastavenia webovej aplikácie',
        icon: faCogs,
        children: [
            {
                id: '13',
                enabled: true,
                name: 'demo',
                label: 'demo text',
            },
        ],
    },
    */
]


// **********************************************************************
// TYPY FARIEB
// **********************************************************************
global.colors = [
    { id: 0, color: '#000000' },
    { id: 1, color: '#444444' },
    { id: 2, color: '#777777' },
    { id: 3, color: '#AAAAAA' },
    { id: 4, color: '#CCCCCC' },
    { id: 5, color: '#E3E5F0' },
    { id: 6, color: '#F4F5FA' },
    { id: 7, color: '#FFFFFF' },

    { id: 13, color: '#0000AA' },
    { id: 8, color: '#0000FF' },
    { id: 14, color: '#509DF4' },
    { id: 15, color: '#ADCCEF' },
    { id: 10, color: '#00FFFF' },
    { id: 12, color: '#8957F4' },
    { id: 9, color: '#FF00FF' },
    { id: 26, color: '#FFAAFF' },


    { id: 11, color: '#FF0000' },
    { id: 21, color: '#F44336' },
    { id: 25, color: '#D8532B' },
    { id: 24, color: '#F77B72' },
    { id: 16, color: '#FFDDDD' },
    { id: 12, color: '#FFAA77' },
    { id: 17, color: '#FFFF00' },
    { id: 18, color: '#FFFF77' },

    { id: 19, color: '#004400' },
    { id: 20, color: '#4D7B4D' },
    { id: 23, color: '#00AA00' },
    { id: 22, color: '#00FF00' },
    { id: 17, color: '#C0E0B8' },
]



// **********************************************************************
// SK - LANGUAGE
// **********************************************************************
export const sk = {
    dph: 20,
    dph_coeficient: 1.2,
    title: 'Datadream s.r.o.',
    description: 'Datadream s.r.o.',
    home: 'Domov',
    web: 'www.datadream.sk',
    degree: '°C',
    login: 'Prihlásenie',
    loginname: 'Prihlasovacie meno (e-mail)',
    login_data: 'Prihlasovacie údaje',
    username: 'Prihlasovacie meno',
    password: 'Heslo',
    password_: 'Heslo (minimálna dĺžka 8 znakov)',
    password_change: 'Zmena hesla',
    password_new: 'Zadajte nové heslo (minimálne 8 znakov)',
    password_new_again: 'Zopakujte nové heslo',
    passwords_not_equal: 'Zadané heslá sa nezhodujú',
    password_ok: 'Heslo bolo úspešne zmenené',
    password_error: 'Pri zmene hesla nastala chyba. Skúste požiadavku opakovať.',
    password_min_len: 'Minimálna dĺžka hesla je 8 znakov',
    password_change: 'Zmeniť heslo',
    login_button: 'Prihlásiť sa',
    lost_password_button: 'Zabudli ste heslo?',
    login_error: 'Nesprávne prihlasovacie údaje',
    lost_password: 'Zabudnuté heslo',
    lost_password_error: 'Zadané heslo sa nenachádza v našom systéme',
    password_changed: 'Zmena hesla prebehla úspešne',

    register: 'Nová registrácia',
    register_button: 'Registrovať sa',
    register_btn: 'Registrovať',
    register_login: 'Prihlasovacie údaje do systému',
    register_ok: 'Registrácia je úspešne dokončená.',
    register_error: 'Pri registrácii nastala chyba. Skúste požiadavku opakovať.',
    forgot_password_email1: 'Zadajte Vašu e-mailovu adresu.',
    forgot_password_email2: 'My vám zašleme odkaz pre zmenu hesla',
    forgot_email_error: 'Zadaná e-mailová adresa už je registrovaná v našom systéme',

    required_data: 'Údaje označené * sú povinné',

    verify: 'Overiť',
    verification_code: 'Overovací kód',
    verification_code_enter: 'Overovací kód sme odoslali na Vašu e-mailovú adresu. Zadajte kód z Vášho e-mailu',
    verification_code_error: 'Nesprávny kód',
    verification_code_resend: 'Nedostali ste overovací kód? Odoslať kód.',

    months: ['Január', 'Február', 'Marec', 'Apríl', 'Máj', 'Jún', 'Júl', 'August', 'September', 'Október', 'November', 'December'],
    months_short: ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'Máj.', 'Jún.', 'Júl.', 'Aug.', 'Sep.', 'Okt.', 'Nov.', 'Dec.'],
    days: ['Nedeľa', 'Pondelok', 'Utorok', 'Streda', 'Štvrtok', 'Piatok', 'Sobota'],
    days_short: ['Ned.', 'Pon.', 'Uto.', 'Str.', 'Štv.', 'Pia.', 'Sob.'],
    today: 'Dnes',

    company: 'Názov firmy',
    name: 'Meno',
    surname: 'Priezvisko',
    name_surname: 'Meno a priezvisko',
    street: 'Ulica',
    street_number: 'číslo',
    psc: 'PSČ',
    town: 'Mesto',
    email: 'E-mail',
    email_error: 'Nesprávny formát e-mailovej adresy',
    mobil: 'Mobil',
    phone: 'Tel.číslo',
    state: 'Štát',
    logo: 'Logo',
    note: 'Poznámka',
    company_logo: 'Firemné logo',
    ico: 'IČO',
    dic: 'DIČ',
    ic_dph: 'IČ-DPH',
    DPH: 'DPH',


    required: 'Povinný údaj',
    username_min_len: 'Minimálna dĺžka prihlasovacieho mena musí byť 8 znakov',
    password_min_len: 'Minimálna dĺžka hesla musí byť 8 znakov',
    email_checking: 'Prebieha kontrola e-mailovej adresy',
    username_checking: 'Prebieha kontrola prihlasovacieho mena',
    password_exists: 'Zadaná e-mailová adresa už je zaregistrovaná. Použite inú adresu.',
    username_exists: 'Zadané prihlasovacie meno už používa iný účet. Použite iné meno',
    logout: 'Odhlásiť sa',
    logout_question: 'Chcete sa odhlásiť',
    loged_user: 'Prihlásený užívateľ',

    active: 'Aktívny',
    inactive: 'Neaktívny',
    number: 'Číslo',
    pin: 'Bezpečnostný pin (6 miestny)',
    user_data: 'Základné údaje',
    label: 'Názov',
    db_error: 'Pri zápise nastala chyba. Skúste požiadavku opakovať',
    send_error: 'Pri odosielaní požiadavky nastala chyba. Skúste požiadavku opakovať',

    new: 'Nový',
    add: 'Pridať',
    ok: 'OK',
    cancel: 'Zrušiť',
    save: 'Uložiť',
    save_changes: 'Uložiť zmeny',
    edit: 'Upraviť',
    search: 'Hľadať',
    send: 'Odoslať',
    send_request: 'Odoslať požiadavku',
    saving: 'Prebieha ukladanie ...',
    subscribe: 'Odoberať',
    mobil_not_supported: 'Mobilná verzia stránky nie je podporovaná',
    userdata: 'Údaje užívateľa',
    change_logins: 'Zmena prihlasovacích údajov',
    new_username: 'Nové prihlasovacie meno',
    new_password: 'Nové heslo',
    username_exists: 'Zadané prihlasovacie meno už používa iný účet.',
    error: 'Chyba',
    finish: 'Dokončiť',
    continue: 'Pokračovať',
    back: 'Späť',
    close: 'Zavrieť',
    delete: 'Vymazať',
    choose: 'Vybrať',
    price: 'Cena',
    money: '€',
    date: 'Dátum',

    firm_data: 'Firemné údaje',
    customers_new: 'Registrácia nového zákazníka',
    customer_new: 'Nový zákazník',
    customer_update: 'Úprava údajov',
    birth_date: 'Dátum narodenia',
    birth_date_holder: 'd. m. r',
    address: 'Adresa',
    contact_data: 'Kontaktné údaje',
    search_text: 'Hľadaný reťazec',
    birthdate_error: 'Chýba dátum narodenia',
    customer_exists: 'Daný zákazník už je registrovaný',

    // POLOZKY - DIALOG
    item_new: 'Nová položka',
    item_edit: 'Úprava položky',
    items_list: 'Zoznam položiek',
    item_show_enabled: 'Zobrazovať len aktívne položky',
    item_inactive: 'Položka je neaktívna',
    item_active: 'Aktívna',
    items_list_search_none: 'Žiadna položka sa nezhoduje s hľadaným výrazom!',

    //FAKTURY
    faktura_new: 'Nová faktúra',
    faktura_edit: 'Úprava faktúry',
    faktura_uhrada: 'Uhradená faktúra',
    
    // ODBERATELIA
    odberatel_new: 'Nový odberateľ',
    odberatel_show_enabled: 'Zobrazovať len aktívne firmy',

    //MOJA FIRMA
    firm_new: 'Registrovať novú firmu',
    firm_show_enabled: 'Zobrazovať len aktívne firmy',

}

