import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, Autocomplete, Box, IconButton, Paper, Select, Alert } from '@mui/material';
import { sk } from './globals.js';
import { Debug, Spacer, ShowOK, ChooseImage, Loading, FormatIban } from './items.js';
import Link from '@mui/material/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faCircleCheck, faEdit, faFolderTree, faHome, faPlus, faSearch, faTurnUp, faXmark, faXmarkCircle, faRotateRight } from '@fortawesome/free-solid-svg-icons';
import { InputLabel } from '@mui/material';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme2-6.css";
import { styles } from './styles.js';
import InputAdornment from '@mui/material/InputAdornment';
import Pagination from '@mui/material/Pagination';
import Chip from '@mui/material/Chip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import CircularProgress from '@mui/material/CircularProgress';


export const Company = (props) => {

    const db_list = 'my_firms';
    const db_list_update = 'my_firms_update';
    const db_list_search = 'my_firms_search';

    //const navigate = useNavigate();

    const [isBusy, setBusy] = useState(false);
    const [reload, setReload] = useState(false);
    const [openAcc, setOpenAcc] = useState(-1);
    const [items, setItems] = useState([]);
    const [itemsFiltered, setItemsFiltered] = useState([]);
    const [redraw, setRedraw] = useState(false);
    const [showNew, setShowNew] = useState(false);
    const [error, setError] = useState('');
    const [showOK, setShowOK] = useState(false);
    const [showEnabled, setShowEnabled] = useState(false); // zobrazovat len aktívne

    // Uprava polozky
    const [editing, setEditing] = useState(false);
    const [selectedItem, setSelectedItem] = useState([]);
    const [selectedID, setSelectedID] = useState(1);

    // SEARCH
    const [search, setSearch] = useState('');
    const [searchArr, setSearchArr] = useState([]);

    // PAGINATION
    const [currentPage, setCurrentPage] = useState(1);
    const [pageMax, setPageMax] = useState(0);

    /*
    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    */

    const boxWidth = 800;
    const boxHeight = 25;

    var lang = sk;

    let params = useParams();

    //const logo = require('./react/app/logo.png');

    useEffect(() => {

        db_items(0, [], false);

        return () => {
        };

    }, []);

    const db_items = async (start, arr, enabled_) => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + db_list, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    typ: props.user_typ,
                    enabled: enabled_,
                    arr: arr,
                    start, start,
                    count: global.items_max,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                // pagination
                var tmp = json.count;
                tmp = Math.ceil(tmp / global.items_max);
                setPageMax(tmp);
            }
            setBusy(false);

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }

    const db_item_update = async (item) => {
        if (isBusy == false) {
            setBusy(true);

            try {
                const response = await fetch(
                    global.db_url + db_list_update, {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        item: item
                    })
                })

                const json = await response.json();

                if (json.ok > 0) {
                    setShowOK(true);
                    if (item.id == -1) {
                        db_items(0, searchArr, showEnabled);
                        setCurrentPage(0);
                    }
                    setOpenAcc(-1);
                    setShowNew(false);
                }
                setBusy(false);

            } catch (error) {
                Debug(error);
                setBusy(false);
            }
        }
    }


    const AddNew = () => {
        // Nova polozka
        setEditing(false);
        setShowNew(true);
    }

    const Edit = (item) => {
        // uprava polozky
        setEditing(true);
        setSelectedItem(item);
        setSelectedID(item.id);
        setShowNew(true);
    }

    const ItemBoxPress = (typ, data) => {
        setError('');

        if (typ == 0) {
            // ZAVRIET
            setOpenAcc(-1);
            setShowNew(false);
        }
        if (typ == 1) {
            // ULOZIT ZMENY POLOZKY
            var tmp = itemsFiltered.filter(x => x.id == data.id);
            if (tmp.length > 0) {
                //tmp[0] = { ...data };
                tmp[0].enabled = data.enabled == true ? true : false;
                tmp[0].type = data.type;
                tmp[0].company = data.company;
                tmp[0].name = data.name;
                tmp[0].surname = data.surname;
                tmp[0].street = data.street;
                tmp[0].street_number = data.street_number;
                tmp[0].psc = data.psc;
                tmp[0].town = data.town;
                tmp[0].country = data.country;
                tmp[0].ico = data.ico;
                tmp[0].dic = data.dic;
                tmp[0].ic_dph = data.ic_dph;
                tmp[0].dph = data.dph;
                tmp[0].register = data.register;
                tmp[0].email = data.email;
                tmp[0].mobil = data.mobil;
                tmp[0].note = data.note;
                tmp[0].logo = data.logo;
                tmp[0].stamp = data.stamp;
                tmp[0].iban = data.iban;
                tmp[0].swift = data.swift;
                tmp[0].days = data.days;
                tmp[0].numbers = data.numbers;
            }
            db_item_update(data);
        }
        if (typ == 2) {
            // NOVY ZAZNAM      
            // zistit ci uz záznam existuje
            var tmp = items.filter(x => x.name.toLowerCase().includes(data.name.toLowerCase()));
            if (tmp.length > 0) {
                setError(lang.item_exist);
            } else {
                db_item_update(data);
            }
        }
        setRedraw(!redraw);
    }

    const SetOpenAcc = (id) => {
        if (openAcc == id) {
            setOpenAcc(-1);
        } else {
            setOpenAcc(id);
        }
    }

    const PageChange = (event, value) => {
        // PAGINATION
        setCurrentPage(value);
        var page = (value - 1) * global.items_max;
        db_items(page, searchArr, showEnabled);
    };


    const ShowOKPress = (value) => {
        setShowOK(false);
    }

    const ItemsSearchResult = (arr, search_) => {

        if (arr != null) {
            if (arr.length > 0) {
                setSearchArr(arr);
                setCurrentPage(1);
                db_items(0, arr, false);
                setSearch(search_);
            } else {
                setSearchArr(arr);
                setCurrentPage(1);
                setSearch(search_);
                setItems([]);
                setItemsFiltered([]);
            }
        } else {
            setSearch(search_);
            setItemsFiltered([]);
        }
    }

    const SearchReset = () => {
        setSearchArr([]);
        setCurrentPage(1);
        db_items(0, [], showEnabled);
        setSearch('');
    }

    const SetShowEnabled = () => {
        var value = !showEnabled;
        setShowEnabled(value);
        db_items(0, searchArr, value);
    }

    const Reload = () => {
        setCurrentPage(0);
        db_items(0, searchArr, showEnabled);
    }

    /* *****************************************************************
        HLADANIE
    ***************************************************************** */
    const ItemsSearch = (props) => {
        /*
                V PHP nastaviť keys
        */

        const [search, setSearch] = React.useState('');
        const [isBusy, setBusy] = React.useState(false);
        const [items, setItems] = React.useState([]);
        const [err, setErr] = React.useState('');

        let { func } = props;
        var lang = sk;

        useEffect(() => {

            return () => {
            };

        }, []);

        const Press = (items_) => {
            func(items_, search);
        }

        const db_search = async (type, txt) => {
            // SEARCH DURING TYPING - type = 0
            // SEARCH AFTER ENTER - type 1 / 2

            setBusy(true);
            var search_ = txt.trim();
            try {
                const response = await fetch(
                    global.db_url + db_list_search, {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        typ: type,
                        search: search_
                    })
                })

                const json = await response.json();

                setBusy(false);
                if (json.ok > 0) {
                    setItems(json.items);
                    if (type > 0) {
                        // vrátiť nájdené položky
                        Press(json.items);
                    }
                }
            } catch (error) {
                Debug(error);
                setBusy(false);
            }

        }


        const SearchProduct = (value) => {
            // PO STLACENI ENTER        
            // zobrazenie vybratého produktu

            if (value.id == undefined) {
                // jedna sa o hladany výraz
                if (search.length > 2) {
                    // zobraziť výsledok
                    db_search(1, value);
                }
            } else {
                // bol označený konkrétny produkt            
                db_search(2, value.id);
            }
        }

        const Search = (search_) => {
            // HLADANIE POCAS PISANIE
            setSearch(search_);
            if (search_.length > 2) {
                setErr('');
                db_search(0, search_);
            }
        }

        return (

            <div style={{ ...styles.Block, justifyContent: 'flex-end', alignItems: 'center' }}>
                <Autocomplete
                    freeSolo
                    disableClearable
                    style={{ width: '100%', maxWidth: 600, backgroundColor: global.theme_gray }}
                    options={items}
                    getOptionLabel={(option) => option.keys ? option.keys : ""}
                    loading={isBusy}
                    size={'small'}
                    onChange={(event, newValue) => { SearchProduct(newValue); }}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                        }
                    }}
                    renderOption={(props, option) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.id}>
                            {/*
                            <div style={{ ...styles.Block, width: 50, marginRight: 10 }}>
                                <img
                                    loading="lazy"
                                    style={{ maxWidth: 50, maxHeight: 50 }}
                                    src={global.product_image + option.path + '/th_' + option.image}
                                    alt=""
                                />
                            </div>
                            */}
                            {option.keys}
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={err == '' ? lang.search : err}
                            onInput={e => Search(e.target.value)}
                            error={err == '' ? false : true}
                            style={{ backgroundColor: global.theme_white }}
                            InputProps={{
                                ...params.InputProps,
                                type: 'search',
                                startAdornment: <InputAdornment position="start"><FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_black }} icon={faSearch} /></InputAdornment>,
                                endAdornment: (
                                    <React.Fragment>
                                        {isBusy ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            </div>
        )

    }

    /* *****************************************************************
        NOVA POLOZKA / EDITACIA POLOZKY
    ***************************************************************** */
    const ItemBox = (props) => {
        const [isBusy, setBusy] = useState(true);
        const [enabled, setEnabled] = useState(false);
        const [error, setError] = useState('');

        const [company, setCompany] = useState('');
        const [companyErr, setCompanyErr] = useState('');
        const [name, setName] = useState('');
        const [surname, setSurname] = useState('');
        const [street, setStreet] = useState('');
        const [streetErr, setStreetErr] = useState('');
        const [streetNumber, setStreetNumber] = useState('');
        const [psc, setPsc] = useState('');
        const [pscErr, setPscErr] = useState('');
        const [town, setTown] = useState('');
        const [townErr, setTownErr] = useState('');
        const [country, setCountry] = useState('');
        const [ico, setICO] = useState('');
        const [icoErr, setICOErr] = useState('');
        const [dic, setDIC] = useState('');
        const [dicErr, setDICErr] = useState('');
        const [icDPH, setIcDPH] = useState('');
        const [dph, setDPH] = useState(0);
        const [register, setRegister] = useState('');
        const [email, setEmail] = useState('');
        const [mobil, setMobil] = useState('');
        const [note, setNote] = useState('');
        const [logo, setLogo] = useState(null);
        const [stamp, setStamp] = useState(null);
        const [iban, setIban] = useState('');
        const [swift, setSwift] = useState('');
        const [days, setDays] = useState('14');
        const [numbers, setNumbers] = useState('FA');

        const margins = 20;

        let { func } = props;

        useEffect(() => {
            if (props.editing == true) {
                setEnabled(props.item.enabled == false ? false : true);
                setCompany(props.item.company == null ? '' : props.item.company);
                setStreet(props.item.street == null ? '' : props.item.street);
                setStreetNumber(props.item.street_number == null ? '' : props.item.street_number);
                setPsc(props.item.psc == null ? '' : props.item.psc);
                setTown(props.item.town == null ? '' : props.item.town);
                setCountry(props.item.country == null ? '' : props.item.country);
                setICO(props.item.ico == null ? '' : props.item.ico);
                setDIC(props.item.dic == null ? '' : props.item.dic);
                setIcDPH(props.item.ic_dph == null ? '' : props.item.ic_dph);
                setDPH(props.item.dph);
                setName(props.item.name == null ? '' : props.item.name);
                setSurname(props.item.surname == null ? '' : props.item.surname);
                setMobil(props.item.mobil == null ? '' : props.item.mobil);
                setEmail(props.item.email == null ? '' : props.item.email);
                setNote(props.item.note == null ? '' : props.item.note);
                setIban(props.item.iban == null ? '' : props.item.iban);
                setSwift(props.item.swift == null ? '' : props.item.swift);
                setLogo(props.item.logo);
                setStamp(props.item.stamp);
                setDays(props.item.days == null ? '14' : props.item.days);
                setNumbers(props.item.numbers == null ? 'FA' : props.item.numbers);
            } else {
                setCountry('Slovensko');
            }

            setBusy(false);

            return () => {
            };

        }, []);

        const Press = (typ) => {
            var data = null;
            var error = '';

            setError('');
            setCompanyErr('')
            setStreetErr('');
            setPscErr('');
            setTownErr('');
            setICOErr('');
            setDICErr('');

            if (typ == 1) {
                // kontrola

                if (company.trim() == '') {
                    error = lang.required;
                    setCompanyErr(lang.required);
                }
                if (street.trim() == '') {
                    error = lang.required;
                    setStreetErr(lang.required);
                }
                if (psc.trim() == '') {
                    error = lang.required;
                    setPscErr(lang.required);
                }
                if (town.trim() == '') {
                    error = lang.required;
                    setTownErr(lang.required);
                }
                if (ico.trim() == '') {
                    error = lang.required;
                    setICOErr(lang.required);
                }
                if (dic.trim() == '') {
                    error = lang.required;
                    setDICErr(lang.required);
                }

                if (error == '') {
                    data = {
                        id: props.editing == true ? props.item.id : -1,
                        enabled: enabled,
                        type: props.user_typ,
                        company: company,
                        name: name,
                        surname: surname,
                        street: street,
                        street_number: streetNumber,
                        psc: psc.replaceAll(' ', ''),
                        town: town,
                        country: country.trim() == '' ? 'Slovensko' : country,
                        ico: ico,
                        dic: dic,
                        ic_dph: icDPH,
                        dph: dph,
                        register: '',
                        email: email,
                        mobil: mobil,
                        note: note,
                        lat: null,
                        lng: null,
                        logo: logo,
                        logo_print: null,
                        stamp: stamp,
                        iban: FormatIban(iban),
                        swift: swift.replaceAll(' ', ''),
                        days: days.trim() == '' ? '14' : days,
                        numbers: numbers
                    }
                } else {
                    setError(error);
                }
            }

            if (error == false) {
                func(typ, data);
            }
        }

        const LogoResult = (filename) => {
            setLogo(filename);
        }

        const StampResult = (filename) => {
            setStamp(filename);
        }

        const dphChange = (event) => {
            var value = parseInt(event.target.value);
            setDPH(value);
        }

        return (
            <div style={{ ...styles.BlockCenter, width: '100%', backgroundColor: global.theme_gray }}>
                <div style={{ width: '95%', paddingBottom: 20 }}>
                    {/* LABEL */}
                    <div style={{ ...styles.BlockRow, width: '100%', marginTop: 20, marginBottom: 10 }}>
                        <p style={{ ...styles.TextNormal, fontWeight: 'bold' }}>{props.editing == false ? lang.item_new : lang.item_edit}</p>
                    </div>

                    <div style={{ ...styles.BlockRow, width: '100%', marginTop: 25 }}>
                        <div style={{ ...styles.BlockLeft, width: '20%' }}>
                            <FormControlLabel
                                style={{ textAlign: 'left', margin: 0, marginTop: 0, padding: 0, fontSize: global.font_tiny }}
                                label={lang.item_active}
                                control={<Checkbox checked={enabled} size={'small'} onChange={() => setEnabled(!enabled)} />}
                            />
                        </div>
                        <div style={{ ...styles.BlockLeft, width: '80%' }}>

                            <Paper elevation={3} style={{ ...styles.Block, paddingTop: 20, paddingBottom: 20 }}>
                                <div style={{ width: '90%' }}>
                                    {/* FAKTURACNE UDAJE */}
                                    <p style={{ ...styles.TextNormal, textAlign: 'left', fontWeight: 'bold' }}>Fakturačné údaje</p>
                                    <div style={{ ...styles.BlockLeft, paddingTop: 20, paddingBottom: margins }}>

                                        {/* NAZOV FIRMY */}
                                        <div style={{ ...styles.BlockLeft, paddingTop: 10 }}>
                                            <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>Názov firmy (*)</p>
                                            <TextField error={companyErr == '' ? false : true} helperText={companyErr} size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={company} onInput={e => setCompany(e.target.value)} />
                                        </div>

                                        {/* ULICA, ČISLO */}
                                        <div style={{ ...styles.BlockRow, paddingTop: margins }}>
                                            <div style={{ width: '70%', marginRight: 10 }}>
                                                <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>Ulica (*)</p>
                                                <TextField error={streetErr == '' ? false : true} helperText={streetErr} size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={street} onInput={e => setStreet(e.target.value)} />
                                            </div>
                                            <div style={{ width: '30%', marginLeft: 10 }}>
                                                <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>číslo</p>
                                                <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={streetNumber} onInput={e => setStreetNumber(e.target.value)} />
                                            </div>
                                        </div>

                                        {/* PSČ, TOWN */}
                                        <div style={{ ...styles.BlockRow, paddingTop: margins }}>
                                            <div style={{ width: '32%', marginRight: 10 }}>
                                                <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>PSČ (*)</p>
                                                <TextField error={pscErr == '' ? false : true} helperText={pscErr} size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={psc} onInput={e => setPsc(e.target.value)} />
                                            </div>
                                            <div style={{ width: '68%', marginLeft: 10 }}>
                                                <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>Mesto (*)</p>
                                                <TextField error={townErr == '' ? false : true} helperText={townErr} size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={town} onInput={e => setTown(e.target.value)} />
                                            </div>
                                        </div>

                                        {/* KRAJINA */}
                                        <div style={{ ...styles.BlockLeft, paddingTop: margins }}>
                                            <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>Krajina</p>
                                            <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={country} onInput={e => setCountry(e.target.value)} />
                                        </div>

                                        {/* ICO, DIC */}
                                        <div style={{ ...styles.BlockRow, paddingTop: margins + 20 }}>
                                            <div style={{ width: '50%', marginRight: 10 }}>
                                                <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>IČO (*)</p>
                                                <TextField error={icoErr == '' ? false : true} helperText={icoErr} size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={ico} onInput={e => setICO(e.target.value)} />
                                            </div>
                                            <div style={{ width: '50%', marginLeft: 10 }}>
                                                <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>DIČ (*)</p>
                                                <TextField error={dicErr == '' ? false : true} helperText={dicErr} size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={dic} onInput={e => setDIC(e.target.value)} />
                                            </div>
                                        </div>


                                        {/* DPH */}
                                        <div style={{ ...styles.BlockRow, paddingTop: margins + 20 }}>
                                            <div style={{ width: '50%', marginRight: 10 }}>
                                                <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>Typ platiteľa</p>
                                                <Select
                                                    color="primary"
                                                    value={dph}
                                                    label={''}
                                                    onChange={dphChange}
                                                    style={{ width: '100%', textAlign: 'left' }}
                                                    size={'small'}
                                                >
                                                    <MenuItem value={0}>{global.dph[0]}</MenuItem >
                                                    <MenuItem value={1}>{global.dph[1]}</MenuItem >
                                                    <MenuItem value={2}>{global.dph[2]}</MenuItem >
                                                </Select>
                                            </div>
                                            {dph == 1 ?
                                                <div style={{ width: '50%', marginLeft: 10 }}>
                                                    <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>IČ DPH</p>
                                                    <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={icDPH} onInput={e => setIcDPH(e.target.value)} />
                                                </div>
                                                :
                                                <div style={{ width: '50%', marginLeft: 10 }}></div>

                                            }
                                        </div>

                                    </div>
                                </div>
                            </Paper>

                            <Paper elevation={3} style={{ ...styles.Block, paddingTop: 20, paddingBottom: 20, marginTop: 40 }}>
                                <div style={{ width: '90%' }}>
                                    {/* KONTAKTNE UDAJE */}
                                    <p style={{ ...styles.TextNormal, textAlign: 'left', fontWeight: 'bold', marginTop: 40 }}>Kontaktná osoba</p>
                                    <div style={{ ...styles.BlockLeft, paddingTop: 20, paddingBottom: margins }}>

                                        {/* MENO, PRIEZVISKO */}
                                        <div style={{ ...styles.BlockRow }}>
                                            <div style={{ width: '50%', marginRight: 10 }}>
                                                <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>Meno</p>
                                                <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={name} onInput={e => setName(e.target.value)} />
                                            </div>
                                            <div style={{ width: '50%', marginLeft: 10 }}>
                                                <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>Priezvisko</p>
                                                <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={surname} onInput={e => setSurname(e.target.value)} />
                                            </div>
                                        </div>

                                        {/* mobil, email */}
                                        <div style={{ ...styles.BlockRow, paddingTop: margins }}>
                                            <div style={{ width: '50%', marginRight: 10 }}>
                                                <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>Mobil</p>
                                                <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={mobil} onInput={e => setMobil(e.target.value)} />
                                            </div>
                                            <div style={{ width: '50%', marginLeft: 10 }}>
                                                <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>Email</p>
                                                <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={email} onInput={e => setEmail(e.target.value)} />
                                            </div>
                                        </div>

                                        {/* POZNAMKA */}
                                        <div style={{ ...styles.BlockLeft, paddingTop: margins }}>
                                            <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>Poznámka</p>
                                            <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={note} onInput={e => setNote(e.target.value)} />
                                        </div>

                                    </div>
                                </div>

                            </Paper>

                            <Paper elevation={3} style={{ ...styles.Block, paddingTop: 20, paddingBottom: 20, marginTop: 40 }}>
                                <div style={{ width: '90%' }}>
                                    {/* BANKOVY UCET */}
                                    <p style={{ ...styles.TextNormal, textAlign: 'left', fontWeight: 'bold', marginTop: 40 }}>Bankový účet</p>
                                    <div style={{ ...styles.BlockLeft, paddingTop: 20, paddingBottom: margins }}>

                                        {/* IBAN, SWIFT */}
                                        <div style={{ ...styles.BlockRow }}>
                                            <div style={{ width: '70%', marginRight: 10 }}>
                                                <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>Iban</p>
                                                <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={iban} onInput={e => setIban(e.target.value)} />
                                            </div>
                                            <div style={{ width: '30%', marginLeft: 10 }}>
                                                <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>Swift</p>
                                                <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={swift} onInput={e => setSwift(e.target.value)} />
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </Paper>

                            <Paper elevation={3} style={{ ...styles.Block, paddingTop: 20, paddingBottom: 20, marginTop: 40 }}>
                                <div style={{ width: '90%' }}>
                                    {/* NASTAVENIA FAKTUR */}
                                    <p style={{ ...styles.TextNormal, textAlign: 'left', fontWeight: 'bold', marginTop: 40 }}>Nastavenia faktúr</p>
                                    <div style={{ ...styles.BlockLeft, paddingTop: 20, paddingBottom: margins }}>

                                        {/* DOBA SPLATNOSTI, CISELNA RADA */}
                                        <div style={{ ...styles.BlockRow }}>
                                            <div style={{ width: '50%', marginRight: 10 }}>
                                                <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>Splatnosť faktúr (počet dní)</p>
                                                <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={days} onInput={e => setDays(e.target.value)} />
                                            </div>
                                            <div style={{ width: '50%', marginLeft: 10 }}>
                                                <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>Číselná rada</p>
                                                <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={numbers} onInput={e => setNumbers(e.target.value)} />
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </Paper>

                            {/* LOGO, PECIATKA */}
                            {isBusy == false ?
                                <div style={{ paddingTop: 40 }}>
                                    <ChooseImage name='id_img_1' folder={''} type={global.image_logo} label={'Logo firmy'} note={'JPG 600x600 px'} image_name={logo} image={logo == null ? null : global.logo + logo} func={LogoResult.bind(this)} />
                                    <ChooseImage name='id_img_2' folder={''} type={global.image_stamp} label={'Pečiatka firmy'} note={'JPG 900x400 px'} image_name={stamp} image={stamp == null ? null : global.stamp + stamp} func={StampResult.bind(this)} />
                                </div>
                                : null}

                        </div>


                    </div>
                    <div style={{ ...styles.Block, marginTop: 0, marginBottom: 0 }}>
                        <div style={{ ...styles.Block, height: 70 }}>
                            {
                                error != '' ?
                                    <Alert severity="error">
                                        Chyba: {error}
                                    </Alert>
                                    : null
                            }
                        </div>
                        <div style={{ ...styles.Block, flexDirection: 'row', marginTop: 0, marginBottom: 0 }}>
                            <Button onClick={() => Press(1)} style={{ ...styles.ButtonSave, textTransform: 'none', fontWeight: 'normal', marginLeft: 5, marginRight: 5 }} >
                                <p style={{ ...styles.TextSmall, color: global.theme_white }}>{lang.save}</p>
                            </Button>
                            <Button onClick={() => Press(0)} style={{ ...styles.ButtonBack, textTransform: 'none', fontWeight: 'normal', marginLeft: 5, marginRight: 5 }} >
                                <FontAwesomeIcon style={{ width: 20, height: 20, marginRight: 10, color: global.theme_white }} icon={faXmark} />
                                <p style={{ ...styles.TextSmall, color: global.theme_white }}>{lang.cancel}</p>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    /* *****************************************************************

        RENDER - HLAVNE OKNO

    ***************************************************************** */
    return (
        <div style={{ ...styles.BlockCenter, height: '100%', marginTop: 20 }}>
            <Paper elevation={3} style={{ ...styles.Block, maxWidth: boxWidth, backgroundColor: global.theme_white, border: '1px solid ' + global.theme_light_gray }}>

                {/* PRIDAŤ NOVU */}
                {showNew == true ?
                    <div style={{ ...styles.Block }}>
                        <ItemBox user_typ={props.user_typ} editing={editing} item={selectedItem} func={ItemBoxPress.bind(this)} />
                        {error != '' ?
                            <div style={{ marginBottom: 10 }}>
                                <p style={{ ...styles.TextTiny, fontWeight: 'bold', color: global.theme_red }}>{error}</p>
                            </div>
                            : null}
                    </div>
                    : null}

                {/* TOP MENU - HLADAT */}
                {showNew == false ?
                    <div style={{ ...styles.BlockRow, width: '95%', marginTop: 20, marginBottom: 20 }}>

                        {/* SEARCH */}
                        <div style={{ ...styles.BlockLeft, width: '50%' }}>
                            <ItemsSearch func={ItemsSearchResult.bind(this)} />
                        </div>

                        <div style={{ ...styles.BlockRight, flexDirection: 'row', width: '50%' }}>
                            <Button onClick={() => AddNew()} style={{ ...styles.ButtonRaw }}>
                                <p style={{ ...styles.TextSmall, marginRight: 10 }}>{lang.firm_new}</p>
                                <div style={{ ...styles.BlockRound, backgroundColor: global.theme_red }}>
                                    <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_white }} icon={faPlus} />
                                </div>
                            </Button>
                        </div>
                    </div>
                    : null}

                {/* SEARCHED - TEXT */}
                {
                    search != '' && showNew == false ?
                        <div style={{ ...styles.BlockLeft, width: '95%', marginBottom: 20 }}>
                            <div style={{ ...styles.BlockLeft, flexDirection: 'row' }}>
                                <p style={{ ...styles.TextSmall, marginRight: 20, marginTop: 6 }}>{lang.search_text}:</p>
                                <Chip label={search} onDelete={SearchReset} />
                            </div>
                        </div>
                        : null
                }

                {/* PRIDAŤ NOVU */}
                {showNew == true ? null :
                    <div style={{ ...styles.Block, width: '95%', paddingTop: 20, paddingBottom: 20 }}>

                        {/* ZOZNAM */}
                        {itemsFiltered.length > 0 ?
                            <div style={{ ...styles.BlockLeft }}>
                                <p style={{ ...styles.TextTiny, marginBottom: 20 }}>{lang.items_list}</p>
                            </div>
                            :
                            search != '' ?
                                <div style={{ ...styles.Block }}>
                                    <p style={{ ...styles.TextSmall, marginBottom: 20, marginTop: 20, fontWeight: 'bold' }}>{lang.items_list_search_none}</p>
                                </div>
                                :
                                <div style={{ ...styles.Block }}>
                                    <p style={{ ...styles.TextSmall, marginBottom: 20, marginTop: 20 }}>{lang.items_list_empty}</p>
                                </div>

                        }
                        {
                            /*
                                ZOZNAM POLOZIEK
                            */
                            itemsFiltered.map(item => (
                                <Accordion key={item.id} elevation={0} expanded={openAcc == item.id ? true : false} onChange={() => SetOpenAcc(item.id)} style={{ width: '100%', backgroundColor: global.theme_gray, border: item.id == selectedID ? '1px solid ' + global.theme_blue : null }}>
                                    <AccordionSummary expandIcon={<FontAwesomeIcon style={{ width: 14, height: 14, color: global.theme_black }} icon={faChevronUp} />}>
                                        <div style={{ ...styles.Block, width: 30, height: boxHeight }}>
                                            <FontAwesomeIcon style={{ width: 16, height: 16, color: item.enabled == true ? global.theme_green : global.theme_red }} icon={item.enabled == true ? faCircleCheck : faXmarkCircle} />
                                        </div>
                                        <div style={{ ...styles.BlockLeft, width: '90%', justifyContent: 'center', height: boxHeight }}>
                                            <p style={{ ...styles.TextSmall, color: global.theme_black, marginLeft: 10 }}>{item.company}</p>
                                        </div>
                                        <div style={{ ...styles.Block, width: 60 }}>
                                            <IconButton onClick={() => Edit(item)} style={{ ...styles.BlockRound, backgroundColor: global.theme_lighter }}>
                                                <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_black }} icon={faEdit} />
                                            </IconButton>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails style={{ backgroundColor: global.theme_grayer }}>
                                        <div style={{ ...styles.BlockLeft, paddingTop: 10, paddingBottom: 10 }}>
                                            <p style={{ ...styles.TextNormal, fontWeight: 'lighter' }}>{'Firemné údaje:'}</p>
                                            <div style={{ ...styles.BlockLeft, paddingTop: 20 }}>
                                                <p style={{ ...styles.TextTiny }}>názov: <b>{item.company}</b></p>
                                                <p style={{ ...styles.TextTiny, marginTop: 5 }}>adresa: {item.street} {item.street_number}, {item.psc} {item.town}, {item.country}</p>
                                                <p style={{ ...styles.TextTiny, marginTop: 20 }}>IČO: {item.ico}</p>
                                                <p style={{ ...styles.TextTiny, marginTop: 5 }}>DIČ: {item.dic}</p>
                                                {item.typ == 1 ?
                                                    <p style={{ ...styles.TextTiny, marginTop: 5 }}>IČ DPH: {item.ic_dph}</p>
                                                    : null}
                                                <p style={{ ...styles.TextTiny, marginTop: 5 }}>Typ platiteľa: {global.dph[item.dph]}</p>
                                            </div>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            ))
                        }
                        {pageMax > 1 ?
                            <div style={{ ...styles.Block, maxWidth: 900, paddingTop: 10, marginTop: 20, paddingBottom: 20, borderTop: '1px solid ' + global.theme_light_gray }}>
                                <Pagination count={pageMax} size={'medium'} page={currentPage} onChange={PageChange} showFirstButton showLastButton />
                            </div>
                            : null}

                        <div style={{ ...styles.BlockRow, paddingTop: 40 }}>
                            <div style={{ ...styles.BlockLeft }}>
                                <FormControlLabel
                                    style={{ textAlign: 'left', margin: 0, marginTop: 20, padding: 0, fontSize: global.font_tiny }}
                                    label={lang.firm_show_enabled}
                                    control={<Checkbox checked={showEnabled} size={'small'} onChange={() => SetShowEnabled()} />}
                                />
                            </div>
                            <div style={{ ...styles.BlockRight, width: 60 }}>
                                <IconButton onClick={() => Reload()} style={{ ...styles.BlockRound, backgroundColor: global.theme_white, marginTop: 20 }}>
                                    <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_medium_gray }} icon={faRotateRight} />
                                </IconButton>
                            </div>
                        </div>

                    </div>


                }
            </Paper >

            <Spacer height={50} />

            {showOK == true ?
                <ShowOK func={ShowOKPress.bind(this)} />
                : null}

            {isBusy == true ?
                < Loading />
                : null}

        </div >
    )
};

