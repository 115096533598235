import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, Autocomplete, Box, IconButton, Paper, Select, Alert } from '@mui/material';
import { sk } from './globals.js';
import { Debug, Spacer, ShowOK, PDFdialog, Loading, GetSplatnost, AskDialog } from './items.js';
import Link from '@mui/material/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faPen, faCheck, faFolderTree, faHome, faPlus, faSearch, faTurnUp, faXmark, faXmarkCircle, faRotateRight, faEye, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { InputLabel } from '@mui/material';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme2-6.css";
import { styles } from './styles.js';
import InputAdornment from '@mui/material/InputAdornment';
import Pagination from '@mui/material/Pagination';
import Chip from '@mui/material/Chip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import CircularProgress from '@mui/material/CircularProgress';
import { addDays, set, setDay } from 'date-fns';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import skLocale from 'date-fns/locale/sk';
import { getDate } from 'date-fns';
import { render } from '@testing-library/react';



export const Faktura = (props) => {
    const [isBusy, setBusy] = useState(true);
    const [error, setError] = useState('');

    const [showMore, setShowMore] = useState(false);
    const [showNumber, setShowNumber] = useState(false);
    const [odberatel, setOdberatel] = useState(null);
    const [firm, setFirm] = useState([]);
    const [firmID, setFirmID] = useState(0);
    const [firms, setFirms] = useState([]);

    const [number, setNumber] = useState('');
    const [company, setCompany] = useState('');
    const [companyErr, setCompanyErr] = useState('');
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [street, setStreet] = useState('');
    const [streetErr, setStreetErr] = useState('');
    const [streetNumber, setStreetNumber] = useState('');
    const [psc, setPsc] = useState('');
    const [pscErr, setPscErr] = useState('');
    const [town, setTown] = useState('');
    const [townErr, setTownErr] = useState('');
    const [country, setCountry] = useState('');
    const [ico, setICO] = useState('');
    const [icoErr, setICOErr] = useState('');
    const [dic, setDIC] = useState('');
    const [dicErr, setDICErr] = useState('');
    const [icDPH, setIcDPH] = useState('');
    const [dph, setDPH] = useState(0);
    const [fakturaNote, setFakturaNote] = useState('');

    const [dateRegister, setDateRegister] = useState(null);
    const [dateSplatnost, setDateSplatnost] = useState(null);
    const [days, setDays] = useState(14);
    const [payment, setPayment] = useState(0);
    const [varSymbol, setVarSymbol] = useState('');
    const [objednavka, setObjednavka] = useState('');
    const [text, setText] = useState('');
    const [items, setItems] = useState([]);
    const [sume, setSume] = useState('0');
    const [showSume, setShowSume] = useState(false);

    const [redraw, setRedraw] = useState(false);
    const [editID, setEditID] = useState(-1);

    // CLOSE
    const [showClose, setShowClose] = useState(false);

    // PDF
    const [showPDF, setShowPDF] = useState(false);
    const [pdfFilename, setPDFFilename] = useState('');
    const [pdfNumber, setPDFNumber] = useState('');

    const margins = 20;
    var lang = sk;

    let { func } = props;

    useEffect(() => {

        var today = new Date();
        var year = String(today.getFullYear());
        var num = props.firm.numbers + year;

        setFirms(props.firms);

        if (props.editing == false && props.copy == false) {
            setCountry('Slovensko');
            setNumber(num + '0001');
            setVarSymbol(num + '0001');
            setDateRegister(today);
            setObjednavka('');
            setDays(props.firm.days);
            setPayment(0);
            setText('');
            setSume('0.00');
            // Nova polozka
            AddNew();
        }

        db_faktura_settings(props.editing == true ? props.item.company_id : props.firm.id,);

        return () => {
        };

    }, []);


    const db_faktura_settings = async (company_id) => {
        setBusy(true);

        var today = new Date();
        var year = String(today.getFullYear());

        try {
            const response = await fetch(
                global.db_url + 'faktura', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    company_id: company_id,
                    year: year,
                    faktura_id: props.editing == true ? props.item.id : 0,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setFirm(json.firm);
                setFirmID(json.firm.id);

                if (props.editing == true) {
                    // EDITACIA FAKTURY
                    setFirmID(json.faktura.company_id);
                    setEmail(json.faktura.email);
                    setCompany(json.faktura.company);
                    setStreet(json.faktura.street);
                    setStreetNumber(json.faktura.street_number);
                    setPsc(json.faktura.psc);
                    setTown(json.faktura.town);
                    setCountry(json.faktura.country);
                    setICO(json.faktura.ico);
                    setDIC(json.faktura.dic);
                    setIcDPH(json.faktura.ic_dph);
                    setDPH(json.faktura.dph);
                    setNumber(json.faktura.number);
                    setVarSymbol(json.faktura.var_symbol);
                    setDateRegister(json.faktura.date_register);
                    setObjednavka(json.faktura.objednavka);
                    setDays(json.faktura.days);
                    setPayment(json.faktura.payment_type);
                    setText(json.faktura.text);
                    setSume(json.faktura.sum);
                    setFakturaNote(json.faktura.note);
                    setDateSplatnost(GetSplatnost(json.faktura.date_register, parseInt(json.faktura.days)));

                    setItems(json.items);
                }

                if (props.editing == false && props.copy == false) {
                    // NOVA FAKTURA
                    var number = String(json.number + 1).padStart(4, '0');
                    var num = props.firm.numbers + year + number;
                    setNumber(num);
                    setVarSymbol(num);

                    var today = new Date();
                    setDateSplatnost(GetSplatnost(today, parseInt(props.firm.days)));

                    setText('Faktúrujeme Vám za dodaný tovar či služby:');
                }

                if (props.copy == true) {
                    // NOVA FAKTURA - OPAKOVANA
                    var number = String(json.number + 1).padStart(4, '0');
                    var num = json.firm.numbers + year + number;
                    setNumber(num);
                    setVarSymbol(num);

                    var today = new Date();
                    setDateSplatnost(GetSplatnost(today, parseInt(json.firm.days)));
                    setDateRegister(today);
                }

            }
            setBusy(false);

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }


    const db_faktura_number = async (company_id) => {
        setBusy(true);

        var today = new Date();
        var year = String(today.getFullYear());

        try {
            const response = await fetch(
                global.db_url + 'faktura_number', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    company_id: company_id,
                    year: year,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var number = String(json.number + 1).padStart(4, '0');
                var num = props.firm.numbers + year + number;
                setNumber(num);
                setVarSymbol(num);
            }
            setBusy(false);

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }



    const db_faktura_update = async (data) => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'faktura_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    firm: props.editing == true ? props.item.company_id : props.firm.id,
                    data: data,
                    items: items
                })
            })

            const json = await response.json();
            setBusy(false);

            if (json.ok > 0) {
                if (data.id == 0) {
                    // nova faktura                    
                    Press(2, data);
                } else {
                    Press(1, data);
                }
            }



        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }


    const db_faktura_preview = async (data) => {
        if (isBusy == false) {
            setBusy(true);

            try {
                const response = await fetch(
                    global.db_url + 'faktura_preview', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        faktura: data,
                        polozky: items,
                        company: firm
                    })
                })

                const json = await response.json();

                if (json.ok > 0) {
                    setPDFFilename(json.filename);
                    setShowPDF(true);
                    setPDFNumber(number);
                }
                setBusy(false);

            } catch (error) {
                Debug(error);
                setBusy(false);
            }
        }
    }



    const Press = (typ, data) => {
        func(typ, data);
    }

    const OdberatelSearchResult = (arr, search_) => {
        if (arr.length > 0) {
            setOdberatel(arr[0]);
            setEmail(arr[0].email);
            setCompany(arr[0].company);
            setStreet(arr[0].street);
            setStreetNumber(arr[0].street_number);
            setPsc(arr[0].psc);
            setTown(arr[0].town);
            setCountry(arr[0].country);
            setICO(arr[0].ico);
            setDIC(arr[0].dic);
            setIcDPH(arr[0].ic_dph);
            setDPH(arr[0].dph);
            setShowMore(false);
        }
    }

    /* *****************************************************************
        HLADANIE
    ***************************************************************** */
    const OdberatelSearch = (props) => {
        /*
                V PHP nastaviť keys
        */

        const [search, setSearch] = React.useState('');
        const [isBusy, setBusy] = React.useState(false);
        const [items, setItems] = React.useState([]);
        const [err, setErr] = React.useState('');

        let { func } = props;
        var lang = sk;

        useEffect(() => {

            return () => {
            };

        }, []);

        const Press = (items_) => {
            func(items_, search);
        }

        const db_search = async (type, txt) => {
            // SEARCH DURING TYPING - type = 0
            // SEARCH AFTER ENTER - type 1 / 2

            setBusy(true);
            var search_ = txt.trim();
            try {
                const response = await fetch(
                    global.db_url + 'faktura_odberatelia_search', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        typ: type,
                        search: search_
                    })
                })

                const json = await response.json();

                setBusy(false);
                if (json.ok > 0) {
                    setItems(json.items);
                    if (type == 2) {
                        // vrátiť nájdené položky
                        Press(json.items);
                    }
                }
            } catch (error) {
                Debug(error);
                setBusy(false);
            }

        }


        const SearchProduct = (value) => {
            // PO STLACENI ENTER        
            // zobrazenie vybratého produktu

            if (value.id == undefined) {
                // jedna sa o hladany výraz
                if (search.length > 2) {
                    // zobraziť výsledok
                    db_search(1, value);
                }
            } else {
                // bol označený konkrétny produkt            
                db_search(2, value.id);
            }
        }

        const Search = (search_) => {
            // HLADANIE POCAS PISANIE
            setSearch(search_);
            if (search_.length > 2) {
                setErr('');
                db_search(0, search_);
            }
        }

        return (

            <div style={{ ...styles.Block, justifyContent: 'flex-end', alignItems: 'center' }}>
                <Autocomplete
                    freeSolo
                    disableClearable
                    style={{ width: '100%', maxWidth: 600, backgroundColor: global.theme_gray }}
                    options={items}
                    getOptionLabel={(option) => option.keys ? option.keys : ""}
                    loading={isBusy}
                    size={'small'}
                    onChange={(event, newValue) => { SearchProduct(newValue); }}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                        }
                    }}
                    renderOption={(props, option) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.id}>
                            {/*
                                <div style={{ ...styles.Block, width: 50, marginRight: 10 }}>
                                    <img
                                        loading="lazy"
                                        style={{ maxWidth: 50, maxHeight: 50 }}
                                        src={global.product_image + option.path + '/th_' + option.image}
                                        alt=""
                                    />
                                </div>
                                */}
                            {option.keys}
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={err == '' ? lang.search : err}
                            onInput={e => Search(e.target.value)}
                            error={err == '' ? false : true}
                            style={{ backgroundColor: global.theme_white }}
                            InputProps={{
                                ...params.InputProps,
                                type: 'search',
                                startAdornment: <InputAdornment position="start"><FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_black }} icon={faSearch} /></InputAdornment>,
                                endAdornment: (
                                    <React.Fragment>
                                        {isBusy ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            </div>
        )

    }


    const dphChange = (event) => {
        var value = parseInt(event.target.value);
        setDPH(value);
    }

    const paymentChange = (event) => {
        var value = parseInt(event.target.value);
        setPayment(value);
    }

    const ShowMore = () => {
        var value = !showMore;

        if (value == false && odberatel != null) {
            odberatel.company = company;
            odberatel.street = street;
            odberatel.street_number = streetErr;
            odberatel.psc = psc;
            odberatel.town = town;
            odberatel.country = country;
            odberatel.ico = ico;
            odberatel.dic = dic;
            odberatel.ic_dph = icDPH;
            odberatel.dph = dph;
        }
        setShowMore(value);
    }

    const OdberatelClear = () => {
        odberatel.company = '';
        odberatel.street = '';
        odberatel.street_number = '';
        odberatel.psc = '';
        odberatel.town = '';
        odberatel.country = '';
        odberatel.ico = '';
        odberatel.dic = '';
        odberatel.ic_dph = '';
        odberatel.dph = '';

        setCompany('');
        setStreet('');
        setStreetNumber('');
        setPsc('');
        setTown('');
        setCountry('');
        setICO('');
        setDIC('');
        setIcDPH('');
        setDPH('');

        setOdberatel(null);
    }

    const AddNew = () => {

        var id = 1;
        if (items.length > 0) {
            var last = (items.slice(-1).pop());
            id = last.id + 1;
        }
        var items_ = items;
        var item = {
            id: id,
            line: 1,
            faktura_id: 0,
            label: '',
            note: '',
            quantity: '1',
            price: '0',
            sum: '0'
        }
        items_.push(item);
        setItems(items_);
        setEditID(id);
        //setRedraw(!redraw);
    }

    const PolozkaResult = (typ, item) => {
        if (typ == 0) {
            // vymazanie polozky
            var tmp = items.filter(x => x.id != item.id);
            setItems(tmp);
            setRedraw(!redraw);
            setSume(Sum(tmp));
        }
        if (typ == 1) {
            // editacia polozky
            setEditID(item.id);
        }
        if (typ == 2) {
            // save polozky
            var tmp = items.find(x => x.id == item.id);
            tmp.label = item.label;
            tmp.quantity = item.quantity;
            tmp.price = item.price;
            tmp.sum = item.sum;
            tmp.note = item.note;
            setEditID(-1);
            setSume(Sum());
        }

    }

    function Sum(items_) {
        if (items_ == undefined) {
            var sum = 0.00;
            items.forEach(item => {
                sum = sum + parseFloat(item.sum);
            })
        } else {
            var sum = 0.00;
            items_.forEach(item => {
                sum = sum + parseFloat(item.sum);
            })
        }


        return sum.toFixed(2);
    }


    const ChangeDateRegister = (date) => {
        setDateRegister(date);
        setDateSplatnost(GetSplatnost(date, parseInt(days)));
    }

    const ChangeSplatnost = (value) => {

        setDays(value);
        if (value != '') {
            setDateSplatnost(GetSplatnost(dateRegister, parseInt(value)));
        }
    }

    const Save = (preview) => {
        var data = {
            id: props.editing == true ? props.copy == true ? 0 : props.item.id : 0,
            type: 0,
            number: number,
            company_id: firm.id,
            company: company,
            email: email,
            street: street,
            street_number: streetNumber,
            psc: psc,
            town: town,
            country: country,
            ico: ico,
            dic: dic,
            ic_dph: icDPH,
            dph: dph,
            register: '',
            date_register: dateRegister,
            days: days,
            var_symbol: varSymbol,
            kon_symbol: '',
            payment_type: payment,
            iban: firm.iban,
            swift: firm.swift,
            sum: sume.replace(',', '.'),
            money: '€',
            note: fakturaNote,
            objednavka: objednavka,
            text: text
        }
        if (preview == false) {
            db_faktura_update(data);
        } else {
            db_faktura_preview(data);
        }
    }

    const firmChange = (event) => {
        var value = parseInt(event.target.value);
        setFirmID(value);

        var tmp = firms.find(x => x.id == value);
        setFirm(tmp);

        db_faktura_number(value);
    }

    const SetNumber = (value) => {
        setNumber(value);
        setVarSymbol(value);
    }

    const PDFdialogResult = () => {
        setShowPDF(false);
    }

    const AskDialogResult = (value) => {
        setShowClose(false);
        if (value == true) {
            Press(0);
        }
    }

    const Close = () => {
        setShowClose(true);
    }

    /* ******************************************************************************************************
    
    POLOZKA
    
    ****************************************************************************************************** */
    const Polozka = (props) => {
        const [label, setLabel] = useState('');
        const [note, setNote] = useState(props.item.id);
        const [quantity, setQuantity] = useState(1);
        const [price, setPrice] = useState(0);
        const [sum, setSum] = useState(0);

        let { func } = props;

        useEffect(() => {
            setLabel(props.item.label);
            setQuantity(props.item.quantity);
            setPrice(parseFloat(props.item.price).toFixed(2));
            setSum(parseFloat(props.item.sum).toFixed(2));
            setNote(props.item.note);

            return () => {
            };

        }, []);

        const Press = (typ) => {
            if (typ == 2) {
                // ulozit hodnoty
                var data = {
                    id: props.item.id,
                    label: label,
                    quantity: quantity,
                    price: price.replace(',', '.'),
                    sum: sum.replace(',', '.'),
                    note: note
                }
                func(typ, data);
            } else {
                func(typ, props.item);
            }
        }

        const SetLabel = (value) => {
            setLabel(value);
        }

        const SetQuantity = (value) => {
            setQuantity(value);

            if (price != '' && value != '') {
                var q = parseInt(value)
                var p = price.replace(',', '.');
                p = parseFloat(p);
                var s = q * p;
                setSum(s.toFixed(2));
            } else {
                setSum('0');
            }
        }

        const SetPrice = (value) => {
            setPrice(value);

            if (quantity != '' && value != '') {
                var q = parseInt(quantity)
                var p = value.replace(',', '.');
                p = parseFloat(p);
                var s = q * p;
                setSum(s.toFixed(2));
            } else {
                setSum('0');
            }
        }

        const SetSum = (value) => {
            setSum(value);
        }

        const SetNote = (value) => {
            setNote(value);
        }


        return (
            props.edit == true ?
                <div style={{ ...styles.Block, backgroundColor: props.index % 2 == 0 ? global.theme_gray : global.theme_white, paddingTop: 10, paddingBottom: 20 }}>
                    {/* POLOZKA */}
                    <div style={{ ...styles.BlockRow, width: '90%', paddingTop: 10, paddingBottom: 10 }}>
                        <div style={{ ...styles.BlockLeft, width: 30 }}>
                            <p style={{ ...styles.TextNormal, marginTop: 7 }}>{props.index}</p>
                        </div>
                        <div style={{ ...styles.BlockRow }}>
                            <div style={{ ...styles.BlockLeft, width: '60%' }}>
                                <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={'Názov'} variant="outlined" value={label} onInput={e => SetLabel(e.target.value)} />
                            </div>
                            <div style={{ ...styles.BlockLeft, width: '10%', marginLeft: 10 }}>
                                <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={quantity} onInput={e => SetQuantity(e.target.value)} />
                            </div>
                            <div style={{ ...styles.BlockLeft, width: '15%', marginLeft: 10 }}>
                                <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={price} onInput={e => SetPrice(e.target.value)} />
                            </div>
                            <div style={{ ...styles.BlockLeft, width: '15%', marginLeft: 10 }}>
                                <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={sum} onInput={e => SetSum(e.target.value)} />
                            </div>
                        </div>
                        <div style={{ ...styles.BlockRight, width: 50 }}>
                            <IconButton onClick={() => Press(2)} style={{ ...styles.BlockRound, backgroundColor: global.theme_dark_green }}>
                                <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_white }} icon={faCheck} />
                            </IconButton>
                        </div>
                    </div>
                    <div style={{ ...styles.BlockRow, width: '90%' }}>
                        <div style={{ ...styles.Block, width: 30 }}></div>
                        <div style={{ ...styles.BlockLeft, width: '100%' }}>
                            <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={'Popis'} variant="outlined" value={note} onInput={e => SetNote(e.target.value)} />
                        </div>
                        <div style={{ ...styles.Block, width: 50 }}>
                        </div>
                    </div>
                </div>
                :
                <div style={{ ...styles.Block, backgroundColor: props.index % 2 == 0 ? global.theme_gray : global.theme_white, paddingTop: 10, paddingBottom: 20 }}>
                    {/* POLOZKA */}
                    <div style={{ ...styles.BlockRow, width: '90%', paddingTop: 10, paddingBottom: 0 }}>
                        <div style={{ ...styles.BlockLeft, width: 50 }}>
                            <IconButton onClick={() => Press(0)} style={{ ...styles.BlockRound, backgroundColor: undefined }}>
                                <FontAwesomeIcon style={{ width: 14, height: 14, color: global.theme_medium_gray }} icon={faXmark} />
                            </IconButton>
                        </div>
                        <div style={{ ...styles.BlockLeft, width: 30 }}>
                            <p style={{ ...styles.TextNormal, marginTop: 7 }}>{props.index}</p>
                        </div>
                        <div style={{ ...styles.BlockRow }}>
                            <div style={{ ...styles.BlockLeft, width: '60%' }}>
                                <p style={{ ...styles.TextSmall, marginTop: 9 }}>{label}</p>
                            </div>
                            <div style={{ ...styles.Block, width: '10%', marginLeft: 10 }}>
                                <p style={{ ...styles.TextSmall, marginTop: 9 }}>{quantity}</p>
                            </div>
                            <div style={{ ...styles.BlockRight, width: '15%', marginLeft: 10 }}>
                                <p style={{ ...styles.TextSmall, marginTop: 9 }}>{price} €</p>
                            </div>
                            <div style={{ ...styles.BlockRight, width: '15%', marginLeft: 10 }}>
                                <p style={{ ...styles.TextSmall, marginTop: 9 }}>{sum} €</p>
                            </div>
                        </div>
                        <div style={{ ...styles.Block, width: 40 }}>
                            {props.show_edit == true ?
                                <IconButton onClick={() => Press(1)} style={{ ...styles.BlockRound, backgroundColor: undefined }}>
                                    <FontAwesomeIcon style={{ width: 14, height: 14, color: global.theme_black }} icon={faPen} />
                                </IconButton>
                                : null}
                        </div>
                    </div>
                    <div style={{ ...styles.BlockRow, width: '90%' }}>
                        <div style={{ ...styles.Block, width: 80 }}></div>
                        <div style={{ ...styles.BlockLeft, width: '100%' }}>
                            <p style={{ ...styles.TextTiny }}>{note}</p>
                        </div>
                        <div style={{ ...styles.Block, width: 40 }}>
                        </div>
                    </div>
                </div>
        )
    }

    /*  **********************************************************************************************************************************************************
        **********************************************************************************************************************************************************
 
        HLAVNA RUTINA - RENDER
 
        **********************************************************************************************************************************************************
        ********************************************************************************************************************************************************** */

    return (
        <div style={{ ...styles.BlockCenter, width: '100%', backgroundColor: global.theme_back }}>
            <div style={{ width: '95%', paddingBottom: 20 }}>

                {/* MOJA FIRMA
                <div style={{ ...styles.BlockLeft, width: '100%', marginTop: 0, marginBottom: 0 }}>
                    <p style={{ ...styles.TextLarge, fontWeight: 'normal', color: global.theme_dark_gray }}>{firm.company}</p>
                </div>
                 */}

                {/* MOJE FIRMY */}
                {isBusy == false ?
                    <div style={{ ...styles.Block, width: '40%', marginLeft: 0 }}>
                        <Select
                            color="primary"
                            value={firmID}
                            label={''}
                            onChange={firmChange}
                            style={{ width: '100%', textAlign: 'left' }}
                            size={'small'}
                        >
                            {firms.map(item => (
                                <MenuItem key={item.id} value={item.id}>{item.company} {item.note}</MenuItem >
                            ))}
                        </Select>
                    </div>
                    : null}

                {/* LABEL */}
                <div style={{ ...styles.BlockRow, width: '100%', marginTop: 10, marginBottom: 10 }}>
                    <p style={{ ...styles.TextXXLarge, fontWeight: 'normal', marginTop: 4 }}>{props.editing == false ? lang.faktura_new : lang.faktura_edit}</p>
                    {showNumber == true ?
                        <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: 160, marginLeft: 10, textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={number} onInput={e => SetNumber(e.target.value)}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    setShowNumber(false);
                                }
                            }} />
                        :
                        <p style={{ ...styles.TextXXLarge, color: global.theme_dark_blue, fontWeight: 'bold', marginLeft: 10, marginTop: 4 }}>{number}</p>
                    }

                    <IconButton onClick={() => setShowNumber(!showNumber)} style={{ ...styles.BlockRound, backgroundColor: undefined, marginTop: 4 }}>
                        <FontAwesomeIcon style={{ width: 12, height: 12, color: global.theme_medium_gray }} icon={showNumber == false ? faPen : faCheck} />
                    </IconButton>
                </div>

                <div style={{ ...styles.Block, width: '100%', marginTop: 25 }}>
                    {/*
                        <div style={{ ...styles.BlockLeft, width: '100%' }}>
                            <FormControlLabel
                                style={{ textAlign: 'left', margin: 0, marginTop: 0, padding: 0, fontSize: global.font_tiny }}
                                label={lang.item_active}
                                control={<Checkbox checked={enabled} size={'small'} onChange={() => setEnabled(!enabled)} />}
                            />
                        </div>
                        */}

                    <div style={{ ...styles.BlockLeft, width: '100%' }}>

                        <Paper elevation={3} style={{ ...styles.Block, paddingTop: 20, paddingBottom: 20 }}>
                            <div style={{ width: '90%' }}>

                                {/* ODBERATEL */}
                                <div style={{ ...styles.BlockRow }}>
                                    <div style={{ ...styles.BlockLeft, width: '30%', marginRight: 10 }}>
                                        <p style={{ ...styles.TextNormal, textAlign: 'left', fontWeight: 'bold', marginTop: 8 }}>Odberateľ</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: '70%', marginLeft: 10 }}>
                                        <OdberatelSearch func={OdberatelSearchResult.bind(this)} />
                                    </div>
                                </div>

                                {showMore == false && company != '' ?
                                    <div style={{ ...styles.BlockLeft, paddingTop: 20 }}>
                                        <p style={{ ...styles.TextSmall, marginTop: 3 }}>{company}</p>
                                        <p style={{ ...styles.TextSmall, marginTop: 3 }}>{street} {streetNumber}</p>
                                        <p style={{ ...styles.TextSmall, marginTop: 3 }}>{psc} {town}</p>
                                        <p style={{ ...styles.TextSmall, marginTop: 3 }}>{email}</p>
                                        <p style={{ ...styles.TextSmall, marginTop: 18 }}>IČO: {ico}</p>
                                        <p style={{ ...styles.TextSmall, marginTop: 3 }}>DIČ: {dic}</p>
                                        {dph == 1 ?
                                            <p style={{ ...styles.TextSmall, marginTop: 3 }}>IČ DPH: {icDPH}</p>
                                            : null}
                                        <p style={{ ...styles.TextSmall, marginTop: 3 }}>Typ platiteľa: {global.dph[dph]}</p>
                                    </div>
                                    : null}

                                {/* ODBERATEL - ZOBRAZIT DETAILY */}
                                <div style={{ ...styles.BlockLeft, paddingTop: 20 }}>
                                    <div style={{ ...styles.BlockRowRaw }}>
                                        <div style={{ ...styles.BlockLeft, width: 120 }}>
                                            <Button onClick={() => ShowMore()} style={{ ...styles.TextLink, textTransform: 'none', padding: 0 }}>{showMore == true ? 'Zatvoriť' : 'Upraviť'}</Button>
                                        </div>
                                        {odberatel != null ?
                                            <div style={{ ...styles.BlockLeft, width: 120 }}>
                                                <Button onClick={() => OdberatelClear()} style={{ ...styles.TextLink, textTransform: 'none', padding: 0 }}>{'Vymazať'}</Button>
                                            </div>
                                            : null}
                                    </div>
                                </div>

                                {showMore == true ?
                                    <div style={{ ...styles.BlockLeft, paddingTop: 20, paddingBottom: margins }}>

                                        {/* NAZOV FIRMY */}
                                        <div style={{ ...styles.BlockLeft, paddingTop: 10 }}>
                                            <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>Názov firmy (*)</p>
                                            <TextField error={companyErr == '' ? false : true} helperText={companyErr} size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={company} onInput={e => setCompany(e.target.value)} />
                                        </div>

                                        {/* ULICA, ČISLO */}
                                        <div style={{ ...styles.BlockRow, paddingTop: margins }}>
                                            <div style={{ width: '70%', marginRight: 10 }}>
                                                <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>Ulica (*)</p>
                                                <TextField error={streetErr == '' ? false : true} helperText={streetErr} size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={street} onInput={e => setStreet(e.target.value)} />
                                            </div>
                                            <div style={{ width: '30%', marginLeft: 10 }}>
                                                <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>číslo</p>
                                                <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={streetNumber} onInput={e => setStreetNumber(e.target.value)} />
                                            </div>
                                        </div>

                                        {/* PSČ, TOWN */}
                                        <div style={{ ...styles.BlockRow, paddingTop: margins }}>
                                            <div style={{ width: '32%', marginRight: 10 }}>
                                                <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>PSČ (*)</p>
                                                <TextField error={pscErr == '' ? false : true} helperText={pscErr} size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={psc} onInput={e => setPsc(e.target.value)} />
                                            </div>
                                            <div style={{ width: '68%', marginLeft: 10 }}>
                                                <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>Mesto (*)</p>
                                                <TextField error={townErr == '' ? false : true} helperText={townErr} size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={town} onInput={e => setTown(e.target.value)} />
                                            </div>
                                        </div>

                                        {/* KRAJINA */}
                                        <div style={{ ...styles.BlockLeft, paddingTop: margins }}>
                                            <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>Krajina</p>
                                            <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={country} onInput={e => setCountry(e.target.value)} />
                                        </div>

                                        {/* ICO, DIC */}
                                        <div style={{ ...styles.BlockRow, paddingTop: margins + 20 }}>
                                            <div style={{ width: '50%', marginRight: 10 }}>
                                                <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>IČO (*)</p>
                                                <TextField error={icoErr == '' ? false : true} helperText={icoErr} size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={ico} onInput={e => setICO(e.target.value)} />
                                            </div>
                                            <div style={{ width: '50%', marginLeft: 10 }}>
                                                <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>DIČ (*)</p>
                                                <TextField error={dicErr == '' ? false : true} helperText={dicErr} size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={dic} onInput={e => setDIC(e.target.value)} />
                                            </div>
                                        </div>


                                        {/* DPH */}
                                        <div style={{ ...styles.BlockRow, paddingTop: margins + 20 }}>
                                            <div style={{ width: '50%', marginRight: 10 }}>
                                                <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>Typ platiteľa</p>
                                                <Select
                                                    color="primary"
                                                    value={dph}
                                                    label={''}
                                                    onChange={dphChange}
                                                    style={{ width: '100%', textAlign: 'left' }}
                                                    size={'small'}
                                                >
                                                    <MenuItem value={0}>{global.dph[0]}</MenuItem >
                                                    <MenuItem value={1}>{global.dph[1]}</MenuItem >
                                                    <MenuItem value={2}>{global.dph[2]}</MenuItem >
                                                </Select>
                                            </div>
                                            {dph == 1 ?
                                                <div style={{ width: '50%', marginLeft: 10 }}>
                                                    <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>IČ DPH</p>
                                                    <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={icDPH} onInput={e => setIcDPH(e.target.value)} />
                                                </div>
                                                :
                                                <div style={{ width: '50%', marginLeft: 10 }}></div>

                                            }
                                        </div>
                                    </div>
                                    : null}
                            </div>
                        </Paper>

                        <Paper elevation={3} style={{ ...styles.Block, paddingTop: 20, paddingBottom: 20, marginTop: 40 }}>
                            <div style={{ width: '90%' }}>
                                {/* FAKTURACNE UDAJE - DATUM, ... */}
                                <p style={{ ...styles.TextNormal, textAlign: 'left', fontWeight: 'bold', marginTop: 0 }}>Fakturačné údaje</p>
                                <div style={{ ...styles.BlockLeft, paddingTop: 20, paddingBottom: margins }}>

                                    {/* DATUM DODANIA, SPLATNOST */}
                                    <div style={{ ...styles.BlockRow }}>
                                        <div style={{ ...styles.BlockLeft, width: '50%', marginRight: 10 }}>
                                            <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>Dátum vystavenia</p>
                                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={skLocale}>
                                                <DatePicker
                                                    label=''
                                                    mask={null}
                                                    style={{ backgroundColor: global.theme_white }}
                                                    value={dateRegister}
                                                    onChange={(newValue) => {
                                                        ChangeDateRegister(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField size="small" disabled={true} {...params} inputProps={{ ...params.inputProps, placeholder: lang.birth_date_holder, disabled: true, style: { backgroundColor: global.theme_white } }} />}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                        <div style={{ ...styles.BlockLeft, width: '50%', marginLeft: 10 }}>
                                            <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>Splatnosť (dní)</p>
                                            <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: 265, textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={days} onInput={e => ChangeSplatnost(e.target.value)} />
                                            <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'normal', marginLeft: 0, marginTop: 5 }}>{dateSplatnost}</p>
                                        </div>
                                    </div>

                                    {/* VAR.SYMBOL */}
                                    <div style={{ ...styles.BlockRow, paddingTop: margins }}>
                                        <div style={{ ...styles.BlockLeft, width: '50%', marginRight: 10 }}>
                                            <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>Variabilný symbol (max.10 znakov)</p>
                                            <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: 265, textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={varSymbol} onInput={e => setVarSymbol(e.target.value)} />
                                        </div>
                                        <div style={{ ...styles.BlockLeft, width: '50%', marginLeft: 10 }}>
                                        </div>
                                    </div>

                                    {/* SPOSOB UHRADY */}
                                    <div style={{ ...styles.BlockRow, paddingTop: margins }}>
                                        <div style={{ ...styles.BlockLeft, width: '50%', marginRight: 10 }}>
                                            <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>Spôsob úhrady</p>
                                            <Select
                                                color="primary"
                                                value={payment}
                                                label={''}
                                                onChange={paymentChange}
                                                style={{ width: 265, textAlign: 'left' }}
                                                size={'small'}
                                            >
                                                <MenuItem value={0}>{global.payment[0]}</MenuItem >
                                                <MenuItem value={1}>{global.payment[1]}</MenuItem >
                                                <MenuItem value={2}>{global.payment[2]}</MenuItem >
                                            </Select>
                                        </div>
                                        <div style={{ ...styles.BlockLeft, width: '50%', marginLeft: 10 }}>
                                        </div>
                                    </div>

                                    {/* CISLO OBJEDNAVKY */}
                                    <div style={{ ...styles.BlockRow, paddingTop: margins }}>
                                        <div style={{ ...styles.BlockLeft, width: '50%', marginRight: 10 }}>
                                            <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>Číslo objednávky</p>
                                            <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: 265, textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={objednavka} onInput={e => setObjednavka(e.target.value)} />
                                        </div>
                                        <div style={{ ...styles.BlockLeft, width: '50%', marginLeft: 10 }}>
                                        </div>
                                    </div>

                                    {/* TEXT */}
                                    <div style={{ ...styles.BlockLeft, paddingTop: margins }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>Úvodný text</p>
                                        <TextField multiline={true} rows={4} size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={text} onInput={e => setText(e.target.value)} />
                                    </div>

                                </div>
                            </div>

                        </Paper>


                        <Paper elevation={3} style={{ ...styles.Block, paddingTop: 20, paddingBottom: 0, marginTop: 40 }}>
                            <div style={{ width: '100%' }}>
                                {/* POLOZKY */}

                                <div style={{ ...styles.Block }}>
                                    <div style={{ ...styles.BlockRow, width: '90%', marginTop: 40 }}>
                                        <div style={{ ...styles.BlockLeft, width: 30 }}>
                                        </div>
                                        <div style={{ ...styles.BlockLeft, width: '60%' }}>
                                            <p style={{ ...styles.TextTiny, color: global.theme_medium_gray }}>Názov položky</p>
                                        </div>
                                        <div style={{ ...styles.Block, width: '10%', marginLeft: 10 }}>
                                            <p style={{ ...styles.TextTiny, color: global.theme_medium_gray }}>Množstvo</p>
                                        </div>
                                        <div style={{ ...styles.BlockRight, width: '15%', marginLeft: 10 }}>
                                            <p style={{ ...styles.TextTiny, color: global.theme_medium_gray }}>Cena</p>
                                        </div>
                                        <div style={{ ...styles.BlockRight, width: '15%', marginLeft: 10 }}>
                                            <p style={{ ...styles.TextTiny, color: global.theme_medium_gray }}>Spolu</p>
                                        </div>
                                        <div style={{ ...styles.BlockLeft, width: 40 }}></div>
                                    </div>
                                </div>

                                {items.map((item, index) => (
                                    <Polozka key={item.id} edit={item.id == editID ? true : false} show_edit={editID >= 0 ? false : true} index={index + 1} item={item} func={PolozkaResult.bind(this)} />
                                ))}
                                <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 40 }}>
                                    <div style={{ ...styles.BlockLeft, width: '90%' }}>
                                        {editID == -1 ?
                                            <Button onClick={() => AddNew()} style={{ ...styles.ButtonRaw }}>
                                                <div style={{ ...styles.BlockRound, backgroundColor: global.theme_red }}>
                                                    <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_white }} icon={faPlus} />
                                                </div>
                                                <p style={{ ...styles.TextSmall, marginLeft: 10 }}>Nová položka</p>
                                            </Button>
                                            : null}
                                    </div>
                                </div>

                                <div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 20 }}>
                                    <div style={{ ...styles.BlockLeft, width: '90%' }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>Interná poznámka</p>
                                        <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={fakturaNote} onInput={e => setFakturaNote(e.target.value)} />
                                    </div>
                                </div>
                                <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 30, backgroundColor: global.theme_light_red }}>
                                    <div style={{ ...styles.BlockRight, width: '90%', marginRight: 70 }}>
                                        <div style={{ ...styles.BlockRowRaw }}>
                                            <p style={{ ...styles.TextLarge, marginTop: 10 }}>Celkom:</p>
                                            {showSume == true ?
                                                <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: 100, marginLeft: 10, textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={sume} onInput={e => setSume(e.target.value)}
                                                    onKeyDown={(event) => {
                                                        if (event.key === 'Enter') {
                                                            setShowSume(false);
                                                        }
                                                    }} />
                                                :
                                                <p style={{ ...styles.TextLarge, marginTop: 10, fontWeight: 'bold', marginLeft: 10 }}>{sume} €</p>
                                            }

                                            <IconButton onClick={() => setShowSume(!showSume)} style={{ ...styles.BlockRound, backgroundColor: undefined, marginTop: 4 }}>
                                                <FontAwesomeIcon style={{ width: 12, height: 12, color: global.theme_medium_gray }} icon={showSume == false ? faPen : faCheck} />
                                            </IconButton>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </Paper>



                    </div>


                </div>
                <div style={{ ...styles.Block, marginTop: 0, marginBottom: 0 }}>
                    <div style={{ ...styles.Block, height: 70 }}>
                        {
                            error != '' ?
                                <Alert severity="error">
                                    Chyba: {error}
                                </Alert>
                                : null
                        }
                    </div>
                    <div style={{ ...styles.BlockRow, flexDirection: 'row', marginTop: 0, marginBottom: 0 }}>
                        <div style={{ ...styles.BlockLeft, width: '50%' }}>
                            {editID == -1 ?
                                <Button onClick={() => Save(false)} style={{ ...styles.ButtonSave, textTransform: 'none', fontWeight: 'normal', marginLeft: 5, marginRight: 5 }} >
                                    <FontAwesomeIcon style={{ width: 20, height: 20, marginRight: 10, color: global.theme_white }} icon={faFloppyDisk} />
                                    <p style={{ ...styles.TextSmall, color: global.theme_white }}>{lang.save}</p>
                                </Button>
                                : null}
                        </div>
                        <div style={{ ...styles.BlockRight, width: '50%' }}>
                            <div style={{ ...styles.BlockRowRaw }}>
                                {editID == -1 ?
                                    <Button onClick={() => Save(true)} style={{ ...styles.ButtonBack, textTransform: 'none', fontWeight: 'normal', marginLeft: 5, marginRight: 5 }} >
                                        <FontAwesomeIcon style={{ width: 20, height: 20, marginRight: 10, color: global.theme_white }} icon={faEye} />
                                        <p style={{ ...styles.TextSmall, color: global.theme_white }}>Náhľad</p>
                                    </Button>
                                    : null}
                                <Button onClick={() => Close(0)} style={{ ...styles.ButtonBack, textTransform: 'none', fontWeight: 'normal', marginLeft: 5, marginRight: 5 }} >
                                    <FontAwesomeIcon style={{ width: 20, height: 20, marginRight: 10, color: global.theme_white }} icon={faXmark} />
                                    <p style={{ ...styles.TextSmall, color: global.theme_white }}>{lang.close}</p>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showPDF == true ?
                <PDFdialog label={'Faktúra: ' + pdfNumber} filename={pdfFilename} func={PDFdialogResult.bind(this)} />
                : null}

            {isBusy == true ?
                < Loading />
                : null}

            {showClose == true ?
                <AskDialog lable='Zatvoriť' question='Chcete zavrieť vyplnenú faktúru bez uloženia zmien?' text='Zmeny nebudú uložené!' button='Zavrieť faktúru' func={AskDialogResult.bind(this)} />
                : null}

        </div>
    )
}