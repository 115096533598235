import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route, Link, useNavigate, Navigate, useLocation, createRoutesFromChildren } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import { sk } from './globals.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList, faAngleLeft, faAngleRight, faClose, faEdit, faPaperPlane, faPhone, faCheck, faXmark, faCircleDot, faDotCircle, faCircle, faBackward, faHome, faUser, faSearch, faRightFromBracket, faAngleDown, faTurnUp, faArrowTurnRight, faFloppyDisk, faImage, faArrowDown, faArrowUp, faFolderTree, faCircleDown, faChevronDown, faChevronRight, faChevronUp, faCircleCheck, faXmarkCircle, faArrowLeft, faSquare, faPrint, faChevronLeft, faBars } from '@fortawesome/free-solid-svg-icons'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/system';
import { faEnvelope, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { Drawer, Slide } from '@mui/material';
//import Slider from "react-slick";
import { Badge } from '@mui/material';
import { Alert, Autocomplete, Button, IconButton, MenuItem, Select, TextField, Paper } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { InputLabel } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme2-6.css";
import { styled } from '@mui/material/styles';
import { styles } from './styles.js';
import Tooltip from '@mui/material/Tooltip';
import Fab from '@mui/material/Fab';
import LinesEllipsis from 'react-lines-ellipsis'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Pagination from '@mui/material/Pagination';
import Chip from '@mui/material/Chip';
import AlertTitle from '@mui/material/AlertTitle';
import Collapse from '@mui/material/Collapse';
import Fade from '@mui/material/Fade';



export const Init = () => {
    //localStorage.removeItem('settings');
}

// ********************************************************
export const LoadLogin = () => {
    var data = localStorage.getItem(global.local_login);
    if (data != null) {
        return JSON.parse(data);
    } else {
        return { logged: false, date: null };;
    }
}

export const SaveLogin = (data) => {
    localStorage.setItem(global.local_login, JSON.stringify(data));
}

// ********************************************************
export const SaveUniqueID = (data) => {
    localStorage.setItem(global.local_unique_id, data);
}

export const LoadUniqueID = () => {
    var data = localStorage.getItem(global.local_unique_id);
    if (data != null) {
        return (data);
    } else {
        return null;
    }
}

// ********************************************************
export const LoadUser = () => {
    var data = localStorage.getItem(global.local_user);
    if (data != null) {
        return JSON.parse(data);
    } else {
        return null;
    }
}

export const SaveUser = (data) => {
    localStorage.setItem(global.local_user, JSON.stringify(data));
}

// ********************************************************
export const LoadSettings = () => {
    var data = localStorage.getItem(global.local_settings);

    if (data != null) {
        return JSON.parse(data);
    } else {
        var tmp = {
            show: global.show_1,
            display: 0
        }
        return tmp;
    }
}

export const SaveSettings = (show, display) => {
    var data = { show: show, display: display };
    localStorage.setItem(global.local_settings, JSON.stringify(data));
}

// ********************************************************
export const Debug = (label, data) => {
    if (global.debugging == true) {
        var dt = data == undefined ? '' : data;
        if (dt == '') {
            console.log(label);
        } else {
            console.log(label, dt);
        }
    }
}

export function ValidateEmail(mail) {
    // kontrola správnosti e-mailovej adresy
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(mail)) {
        return (true)
    }
    return (false)
};


export const Menu = (props) => {
    /*
            MENU - na lavej strane

            USAGE:
            <Menu func={MenuPress.bind(this)} />

            const MenuPress = (value) => {
                Debug(value)
            }

    */

    const [index, setIndex] = useState(0);
    const [childrenIndex, setChildrenIndex] = useState(0);
    const [openAcc, setOpenAcc] = useState(-1);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    //const logo = require('./react/app/logo_white.png');

    const boxHeight = 25;

    var lang = sk;
    let { func } = props;
    const navigate = useNavigate();


    useEffect(() => {

        return () => {
        };

    }, []);

    const Press = (type, id, item) => {
        func(type, parseInt(id));
    }

    const Click = (id, children_id, item) => {

        if (id == index && children_id == 0) {
            setIndex(0);
            setChildrenIndex(0);

            Press(0, 0, 0);
        } else {
            setIndex(id);
            setChildrenIndex(children_id);

            Press(id, children_id, item)
        }
    }

    const SetOpenAcc = (id) => {
        if (openAcc == id) {
            setOpenAcc(-1);
        } else {
            setOpenAcc(id);
        }
        setChildrenIndex(-1);
    }

    const GoHome = () => {
        setChildrenIndex(-1);
        setOpenAcc(-1);
        Press(0, 0, 0);
    }

    const Submenu = (props) => {
        var children = props.item;

        return (
            <div style={{ width: '100%', marginBottom: 0 }}>
                {
                    /* ------------------------------------------------
                        SUBMENU - CHILDRENS
                    ------------------------------------------------ */
                }
                {children != null ?
                    children.map(item => {
                        return (
                            item.enabled == true ?
                                <Button key={item.id} onClick={() => Click(props.menu_id, item.id, item)} style={{ ...styles.ButtonMenu, background: childrenIndex == item.id ? global.theme_gradient : global.theme_dark }}>
                                    <FontAwesomeIcon style={{ width: 8, height: 8, marginRight: 10, marginLeft: 15, color: global.theme_light_gray }} icon={faCircle} />
                                    <div style={{ ...styles.BlockLeft }}>
                                        <p style={{ ...styles.TextTiny, color: global.theme_light_gray }}>
                                            {item.name}
                                        </p>
                                    </div>
                                </Button >
                                : null
                        )
                    })
                    : null
                }

            </div>
        )
    }

    return (
        <Paper elevation={10} style={{ ...styles.BlockMenu, height: props.height }}>

            <div style={{ ...styles.Block, height: 70, backgroundColor: global.theme_dark }}>
                <p style={{ ...styles.TextLarge, marginTop: 0, color: global.theme_white, fontWeight: 'lighter' }}>{lang.title}</p>
            </div>

            {/* LOGO */}
            <div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 20, backgroundColor: global.theme_logo_background, width: global.menu_width }}>
                {props.company.logo == null ?
                    <div style={{ ...styles.Block }}>
                        <img onClick={() => GoHome()} src={global.images + 'app/logo.png'} style={{ maxHeight: 160, maxWidth: 160, cursor: 'pointer' }}></img>
                    </div>
                    :
                    <img onClick={() => GoHome()} src={global.images + '/logo/' + props.company.logo} style={{ maxHeight: 160, maxWidth: 160, cursor: 'pointer' }}></img>
                }
            </div>

            {/* HOME BUTTON */}
            <Button onClick={() => GoHome()} style={{ ...styles.ButtonMenu, backgroundColor: global.theme_dark, marginBottom: 10, marginTop: 20 }}>
                <FontAwesomeIcon style={{ width: 20, height: 20, marginRight: 10, marginLeft: 5, color: global.theme_light_gray }} icon={faHome} />
                <div style={{ ...styles.BlockLeft }}>
                    <p style={{ ...styles.TextSmall, color: global.theme_light_gray, marginLeft: 5 }}>
                        {lang.home}
                    </p>
                </div>
            </Button >

            {/* MENU */
                global.menuitems.map(item => (
                    <Accordion key={item.id} elevation={0} expanded={openAcc == item.id ? true : false} onChange={() => SetOpenAcc(item.id)} style={{ ...styles.BlockLeft, width: '100%', backgroundColor: global.theme_dark }}>
                        <AccordionSummary expandIcon={<FontAwesomeIcon style={{ width: 14, height: 14, color: global.theme_medium_gray }} icon={faChevronUp} />}>
                            <div style={{ ...styles.Block, width: 30, height: boxHeight }}>
                                <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_white }} icon={item.icon} />
                            </div>
                            <div style={{ ...styles.BlockLeft, justifyContent: 'center', height: boxHeight, width: global.menu_width - 125 }}>
                                <p style={{ ...styles.TextSmall, color: global.theme_white, marginLeft: 10, textAlign: 'left' }}>{item.name}</p>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Submenu item={item.children} menu_id={item.id} />
                        </AccordionDetails>
                    </Accordion>
                ))
            }
        </Paper>
    )

};



export const MenuTop = (props) => {
    /*
            MENU - horná lišta

            USAGE:
            <MenuTop func={MenuTopPress.bind(this)} />

            const MenuTopPress = (value) => {
                Debug(value)
            }

    */
    const [isBusy, setBusy] = useState(false);
    const [isOpen, setOpen] = useState(false);


    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [err, setErr] = useState('');
    const [showLogout, setShowLogout] = useState(false);


    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const navigate = useNavigate();
    const location = useLocation();

    //const logo = require('./react/app/logo.png');

    let { func } = props;
    var lang = sk;

    const BarHeight = 70;
    const BarHeightSmall = 50;

    useEffect(() => {

        return () => {
        };

    }, []);

    const Press = (type, value, item) => {
        func(type, value);
    }

    const User = () => {
        Press(999, null);
    }

    const OpenMenu = () => {
        setOpen(true);
    }

    const CloseMenu = () => {
        setOpen(false);
    }

    const ChangeMenu = (id) => {
        setOpen(false);

    }

    const MenuPress = (id, children_id, item) => {
        setOpen(false);
        func(id, children_id, item);
    }

    const Logout = () => {
        setShowLogout(true);
    }

    const AskLogoutResult = (value) => {
        if (value == true) {
            var today = new Date();
            var data = { logged: false, date: today };
            SaveLogin(data);
            SaveUser([]);
            navigate('/login');
        } else {
            setShowLogout(false);
        }
    }

    return (
        <div style={{ ...styles.BlockRow, background: 'linear-gradient(to right,#CCCCCC,#EEEEEE)', height: isSmall ? BarHeightSmall : BarHeight, borderBottom: '1px solid ' + global.theme_light_gray }}>
            {isSmall ?
                <div style={{ ...styles.BlockRowRaw, width: 100, justifyContent: 'flex-start', alignItems: 'center', height: isSmall ? BarHeightSmall : BarHeight, paddingLeft: 10 }}>
                    <IconButton onClick={() => OpenMenu()} style={{ ...styles.Block, height: 40, width: 40 }}>
                        <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_black }} icon={faBars} />
                    </IconButton>
                    <IconButton onClick={() => Press(0)} style={{ ...styles.Block, height: 40, width: 40 }}>
                        <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_light }} icon={faHome} />
                    </IconButton>

                    <Drawer
                        anchor={'left'}
                        open={isOpen}
                        onClose={CloseMenu}
                    >
                        <div style={{ ...styles.BlockCenter, backgroundColor: global.theme_darker, width: global.menu_width, height: '100%' }}>
                            <div style={{ ...styles.BlockRight }}>
                                <IconButton onClick={() => CloseMenu()} style={{ ...styles.Block, height: 40, width: 40 }}>
                                    <FontAwesomeIcon style={{ color: global.theme_white }} icon={faXmark} />
                                </IconButton>
                            </div>
                            <Menu company={props.company} height={props.height} func={MenuPress.bind(this)} />
                        </div>
                    </Drawer>
                </div>
                :
                <div style={{ ...styles.BlockLeft, width: '50%', justifyContent: 'center', alignItems: 'flex-start', height: BarHeight, paddingLeft: 10 }}>
                    {props.label != null ?
                        <p style={{ ...styles.TextNormal, fontWeight: 'bold', color: global.theme_dark_gray }}>{props.label}</p>
                        :
                        null
                    }
                </div>
            }

            <div style={{ ...styles.BlockRight, width: isSmall ? '100%' : '50%', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', height: isSmall ? BarHeightSmall : BarHeight, paddingRight: 10 }}>
                <div style={{ display: 'flex' }}>
                    <Button onClick={() => User()} style={{ textTransform: 'none', fontWeight: 'normal' }} >
                        <FontAwesomeIcon style={{ width: 20, height: 20, marginRight: 10, color: global.theme_dark_blue }} icon={faUser} />
                        <p style={{ ...styles.TextSmall, color: global.theme_dark_gray }}>{props.name} {props.surname}</p>
                    </Button>
                    <IconButton onClick={() => Logout()} style={{ width: 35, height: 35, borderRadius: 0, marginLeft: 10 }}>
                        <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_black }} icon={faRightFromBracket} />
                    </IconButton>
                </div>
            </div>

            {showLogout == true ?
                <AskLogout func={AskLogoutResult.bind(this)} />
                : null}

        </div>
    )

};



export const Loading = (props) => {
    /*
        USAGE: 
                <Loading center={true} />
    */

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });


    const logo = require('./react/app/logo_small.png');

    return (
        <Backdrop sx={{ color: '#000000', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true} onClick={null} style={{ backgroundColor: '#00000000' }}>
            <div style={{ ...styles.Block, marginLeft: isSmall ? 0 : props.center != undefined && props.center == true ? 0 : global.menu_width - 40, width: 80, height: 80, backgroundColor: global.theme_white, borderRadius: 40 }}>
                <img src={logo} style={{ width: 50, height: 50 }}></img>
                <CircularProgress style={{ position: 'absolute', color: global.theme_light }} size={70} />
            </div>
        </Backdrop>);
}



export const PlusMinus = (props) => {
    /*

            USAGE:
            <PlusMinus quantity={quantity} func={PlusMinusPress.bind(this)} />

            const PlusMinusPress = (value) => {
                Debug(value)
            }

    */


    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [quantity, setQuantity] = useState(1);

    var lang = sk;
    let { func } = props;

    useEffect(() => {
        setQuantity(props.quantity);

        return () => {
        };

    }, []);

    const Press = (id) => {
        func(id, props.id);
    }

    const ChangeQuantity = (id) => {
        var q = parseInt(quantity);
        q = q + id;
        if (q < 1) {
            q = 1;
        }
        setQuantity(q);
        Press(q);
    }

    const EnterQuantity = (text) => {
        if (/^\d+$/.test(text)) {
            setQuantity(text);
        }
        if (text.trim() == '') {
            //setQuantity(text);
            text = '1';
        }
        if (parseInt(text) < 1) {
            text = '1';
            setQuantity(text);
        }
        Press(parseInt(text));
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'row', width: 175, justifyContent: 'flex-end' }}>
            <div style={{ display: 'flex', flexDirection: 'row', height: 40, justifyContent: 'center', alignItems: 'center', borderRadius: 4 }}>
                <IconButton onClick={() => ChangeQuantity(-1)} style={{ margin: 0, width: 35, height: 35, backgroundColor: global.theme_white }}>
                    <FontAwesomeIcon style={{ width: 14, height: 14, marginRight: 0, color: global.theme_light }} icon={faMinus} />
                </IconButton>
                <div style={{ display: 'flex', height: 40, justifyContent: 'center', alignItems: 'center' }}>
                    <TextField
                        style={{ fontSize: global.fone_normal, margin: 0, width: 90, border: '0px', textAlign: 'center' }}
                        variant="outlined"
                        label={''}
                        value={quantity}
                        size={'small'}
                        onInput={e => EnterQuantity(e.target.value)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{lang.pieces}</InputAdornment>,
                        }}
                    />
                </div>
                <IconButton onClick={() => ChangeQuantity(1)} style={{ margin: 0, width: 35, height: 35, backgroundColor: global.theme_white }}>
                    <FontAwesomeIcon style={{ width: 14, height: 14, marginRight: 0, color: global.theme_light }} icon={faPlus} />
                </IconButton>
            </div>
        </div>
    )

};


export const UserData = (props) => {
    /*

            USAGE:
            <UserData user={user} func={UserDataPress.bind(this)} />

            const UserDataPress = (value) => {
                Debug(value)
            }

    */


    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [isBusy, setBusy] = useState(false);
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    var lang = sk;
    let { func } = props;

    useEffect(() => {
        setName(props.user.name);
        setSurname(props.user.surname);
        setUsername(props.user.username);

        return () => {
        };

    }, []);

    const Press = (id, data) => {
        func(id, data);
    }

    const db_update = async () => {

        if (isBusy == false) {
            setBusy(true);

            var data = {
                name: name,
                surname: surname,
            }

            if (username.trim() != '') {
                data.username = username;
            }

            if (password.trim() != '') {
                data.password = password;
            } else {
                data.password = '';
            }

            try {
                const response = await fetch(
                    global.db_url + 'login_update', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        id: props.user.id,
                        data: data
                    })
                })

                const json = await response.json();
                setBusy(false);

                if (json.ok > 0) {
                    data.id = props.user.id;
                    data.typ = props.user.typ;
                    data.enabled = props.user.enabled;
                    data.company_id = props.user.company_id;
                    Press(true, data);
                }

            } catch (error) {
                Debug(error);
                setBusy(false);
            }
        }
    };

    const db_check_email = async () => {

        if (isBusy == false) {
            setBusy(true);
            try {
                const response = await fetch(
                    global.db_url + 'check_email', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        id: props.user.id,
                        username: username
                    })
                })

                const json = await response.json();
                setBusy(false);

                if (json.ok > 0) {
                    if (json.count > 0) {
                        setError(lang.username_exists);
                    } else {
                        db_update();
                    }
                }

            } catch (error) {
                Debug(error);
                setBusy(false);
            }
        }
    };


    const Save = () => {
        setError('');
        db_check_email();
    }


    return (
        <Dialog open={true} maxWidth={'lg'}>
            <div style={{ ...styles.BlockRow, backgroundColor: global.theme_dark, height: 50, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '100%', paddingLeft: 10 }}>
                    <p style={{ ...styles.TextLarge, color: global.theme_white }}>{lang.userdata}</p>
                </div>
                <div style={{ width: 50 }}>
                    <IconButton onClick={() => Press(false, false)} style={{ ...styles.BlockRound, width: 30, height: 30, backgroundColor: global.theme_light, alignSelf: 'flex-end' }}>
                        <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_white }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>

            <div style={{ ...styles.Block, width: isSmall ? 300 : 600, padding: isSmall ? 0 : 20, paddingTop: 20, paddingBottom: 20 }}>
                {/* MENO, PRIEZVISKO */}
                <div style={{ ...isSmall ? styles.Block : styles.BlockRow, width: isSmall ? '90%' : undefined }}>
                    <div style={{ ...styles.BlockLeft, width: isSmall ? '100%' : '50%', marginRight: isSmall ? 0 : 10 }}>
                        <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.name}</p>
                        <TextField style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" size={'small'} value={name} onInput={e => setName(e.target.value)} />
                    </div>
                    <div style={{ ...styles.BlockLeft, width: isSmall ? '100%' : '50%', marginLeft: isSmall ? 0 : 10, marginTop: isSmall ? 20 : 0 }}>
                        <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.surname}</p>
                        <TextField style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" size={'small'} value={surname} onInput={e => setSurname(e.target.value)} />
                    </div>
                </div>

                {/* USERNAME, PASSWORD */}
                <div style={{ ...isSmall ? styles.Block : styles.BlockLeft, marginTop: 30 }}>
                    <p style={{ ...styles.TextNormal }}>{lang.change_logins}</p>
                </div>
                <div style={{ ...isSmall ? styles.Block : styles.BlockRow, marginTop: 10, width: isSmall ? '90%' : undefined }}>
                    <div style={{ ...styles.BlockLeft, width: isSmall ? '100%' : '50%', marginRight: isSmall ? 0 : 10 }}>
                        <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.email}</p>
                        <TextField style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" size={'small'} value={username} onInput={e => setUsername(e.target.value)} />
                    </div>
                    <div style={{ ...styles.BlockLeft, width: isSmall ? '100%' : '50%', marginLeft: isSmall ? 0 : 10, marginTop: isSmall ? 20 : 0 }}>
                        <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.new_password}</p>
                        <TextField type={'password'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" size={'small'} value={password} onInput={e => setPassword(e.target.value)} />
                    </div>
                </div>

            </div>

            <div style={{ ...styles.Block, width: isSmall ? 300 : 600, height: 110 }}>
                <div style={{ height: 50 }}>
                    {isBusy == true ?
                        <CircularProgress color="inherit" size={20} />
                        : error != '' ?
                            <Alert severity="error">
                                {error}
                            </Alert>
                            : null
                    }
                </div>
                <div style={{ ...isSmall ? styles.Block : styles.BlockRowRaw }}>
                    <Button onClick={() => Save()} style={{ ...styles.ButtonGreen, margin: 10 }}>{lang.save}</Button>
                    <Button onClick={() => Press(false, false)} style={{ ...styles.ButtonBack, margin: 10 }}>{lang.cancel}</Button>
                </div>
            </div>
        </Dialog >
    )

};


export const UniqueID = () => {
    let s4 = () => { return Math.floor((1 + Math.random() * 0x10000)).toString(16).substring(1); }
    return (Date.now() + '-' + s4() + s4() + s4() + '-' + s4() + s4() + s4());
}


export const GetPriceRound = (price_) => {
    // VRATI zaokrúhlenú cenu
    var tmp = parseFloat(price_).toFixed(2);
    return tmp;
}

export const GetPrice = (price_) => {
    // VRATI CENU NAFORMATOVANU 0,00
    var tmp = GetPriceRound(price_).toString();;
    return tmp.replace('.', ',');
}

export const GetCost = (b2b, cost, coeficient) => {
    // VYPOCET CENY S A BEZ DPH - podla toho ci je zákazník B2B
    if (b2b == true) {
        // B2B
        return cost;
    }
    if (b2b == false) {
        // KONCOVY ZAKAZNIK
        return (cost * coeficient);
    }
}

export const GetSume = () => {

    return 0;
}


export const ChooseImage = (props) => {
    /*      VYBER JEDNEHO OBRAZKU NA UPLOAD

            USAGE:
            
            <ChooseImage name='id_img_1' folder={''} type={global.image_logo} label={'Nadpis'} note={'600x600 px'} image_name={imageName} image={logo == null ? null : global.logo + logo} func={LogoResult.bind(this)} />

            const LogoResult = (filename) => {                
            }

    */
    const [isBusy, setBusy] = useState(false);
    const [file, setFile] = useState(null); // celá adresa k obrázku
    const [fileName, setFileName] = useState(null); // len názov obrázku
    const [type, setType] = useState(0);

    const [showImage, setShowImage] = useState(false);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const inputRef = useRef(null);
    let { func } = props;
    var lang = sk;

    useEffect(() => {

        setFile(props.image);
        setFileName(props.image_name);
        setType(props.type);

        return () => {
        };

    }, []);


    const Press = (img) => {
        func(img);
    }

    const image_upload = async (file) => {
        // UPLOAD IMAGE TO SERVER
        setBusy(true);
        const data = new FormData();
        data.append('typ', type);
        data.append("name", "image");
        if (props.folder != undefined) {
            data.append("folder", props.folder);
        }
        data.append("file_attachment", file);

        try {
            const response = await fetch(
                global.db_url + 'upload_image', {
                method: 'POST',
                body: data
            }
            );
            const json = await response.json();

            if (json.ok > 0) {
                // ALL OK   
                setFile(global.web + '/' + json.file);
                setFileName(json.filename);
                Press(json.filename);
            }
            inputRef.current.value = null;
            setBusy(false);
            return;

        } catch (error) {
            setBusy(false);
            console.log(error);
        }
        
    };

    const image_delete = async (image) => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'delete_image', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    image: image,
                    typ: type,
                    folder: props.folder
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setFile(null);
                inputRef.current.value = null;
                Press(null);
            }
            setBusy(false);

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    };

    function handleChange(e) {
        var img = URL.createObjectURL(e.target.files[0]);
        var file = e.target.files[0];
        setFile(img);
        image_upload(file);        
    }

    const ClearImage = () => {
        image_delete(fileName);
    }

    return (
        <div style={{ ...styles.BlockLeft }}>
            <p style={{ ...styles.TextTiny, fontWeight: 'bold', marginBottom: 10 }}>{props.label}</p>
            <div style={{ ...styles.BlockLeft, flexDirection: 'row' }}>
                {file != null ?
                    <div style={{ ...styles.Block, width: 120, height: 120, marginRight: 10, borderRadius: 10, backgroundColor: global.theme_gray }}>
                        <Tooltip title={lang.delete}>
                            <IconButton onClick={() => ClearImage()} style={{ ...styles.BlockRound, width: 20, height: 20, backgroundColor: global.theme_dark_gray, alignSelf: 'flex-end' }}>
                                <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_white }} icon={faXmark} />
                            </IconButton>
                        </Tooltip>
                        <div style={{ ...styles.Block, height: '100%' }}>
                            {isBusy == true ?
                                <CircularProgress color="inherit" size={20} />
                                :
                                <img onClick={() => setShowImage(true)} src={file} style={{ maxWidth: 90, maxHeight: 90, aspectRatio: 1.0, cursor: 'pointer' }} />
                            }
                        </div>
                    </div>
                    : null}
                <div>
                    <label htmlFor={props.name} style={{ ...styles.Block, width: 120, height: 120, background: global.theme_white, justifyContent: 'center', alignItems: 'center', cursor: 'pointer', border: '2px solid ' + global.theme_dark_gray, borderRadius: 10 }}>
                        <p style={{ ...styles.TextTiny, marginBottom: 10, color: global.theme_dark_gray }}>{props.note}</p>
                        <FontAwesomeIcon style={{ width: 40, height: 40, color: global.theme_dark_gray }} icon={faImage} />
                        <p style={{ ...styles.TextTiny, marginTop: 5, color: global.theme_dark_gray }}>{lang.choose_image}</p>
                    </label>
                    <input ref={inputRef} name={props.name} onChange={handleChange} id={props.name} style={{ visibility: "hidden" }} type={"file"}></input>
                </div>

            </div>

            {showImage == true ?
                <Dialog open={true} maxWidth={'lg'} onBackdropClick={() => setShowImage(false)} >
                    <div style={{ ...styles.BlockRow, backgroundColor: global.theme_dark, height: 60, justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ width: '100%', paddingLeft: 10 }}>
                            <p style={{ ...styles.TextLarge, color: global.theme_white }}>{lang.image_preview}</p>
                        </div>
                        <div style={{ width: 50 }}>
                            <IconButton onClick={() => setShowImage(false)} style={{ ...styles.BlockRound, width: 36, height: 36, backgroundColor: global.theme_light, alignSelf: 'flex-end' }}>
                                <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_white }} icon={faXmark} />
                            </IconButton>
                        </div>
                    </div>
                    <img src={file} style={{ maxWidth: 600, maxHeight: 600, aspectRatio: 1.0 }} />
                </Dialog >
                : null}
        </div>
    );
}


export const Spacer = (props) => {
    /*
            USAGE:
            <Spacer height={50} />
    */

    return (
        <div style={{ ...styles.Block, height: props.height == undefined ? 20 : parseInt(props.height) }}>
            <p></p>
        </div >
    );
}




export const Template = (props) => {
    /*
            USAGE:
            <BonusInfo lang={lang} language={language} func={BonusInfoPress.bind(this)} />

            const BonusInfoPress = (value) => {
                Debug(value)
            }
            */
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = sk;

    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = (typ, value) => {
        func(typ, value);
    }

    const Save = () => {
        Press(0, 0);
    }

    return (
        <Dialog open={true} maxWidth={'lg'}>
            <div style={{ ...styles.BlockRow, backgroundColor: global.theme_dark, height: 50, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '100%', paddingLeft: 10 }}>
                    <p style={{ ...styles.TextLarge, color: global.theme_white }}>{lang.userdata}</p>
                </div>
                <div style={{ width: 50 }}>
                    <IconButton onClick={() => Press(false, false)} style={{ ...styles.BlockRound, width: 30, height: 30, backgroundColor: global.theme_light, alignSelf: 'flex-end' }}>
                        <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_white }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>

            <div style={{ ...styles.Block, width: 600 }}>
                <p>Body</p>
            </div>

            <div style={{ ...styles.Block, width: 600, height: 110 }}>
                <div style={{ height: 50 }}>
                    {isBusy == true ?
                        <CircularProgress color="inherit" size={20} />
                        : error != '' ?
                            <Alert severity="error">
                                {error}
                            </Alert>
                            : null
                    }
                </div>
                <div style={{ ...styles.BlockRow, width: undefined }}>
                    <Button onClick={() => Save()} style={{ ...styles.ButtonGreen, margin: 10 }}>{lang.save}</Button>
                    <Button onClick={() => Press(false, false)} style={{ ...styles.ButtonBack, margin: 10 }}>{lang.cancel}</Button>
                </div>
            </div>
        </Dialog >
    );
}


export const Today = () => {
    var today = new Date();

    var d = String(today.getDate()).padStart(2, '0');
    var m = String(today.getMonth() + 1).padStart(2, '0');
    var y = String(today.getFullYear());
    today = y + '-' + m + '-' + d;

    return today;
}

export const GetDate = (str) => {
    var today = new Date(str);

    var d = String(today.getDate()).padStart(2, '0');
    var m = String(today.getMonth() + 1);
    var y = String(today.getFullYear());
    today = d + '. ' + m + '. ' + y;

    return today;
}

export const GoHome = () => {
    window.scrollTo(0, 0);
}

export const SelectColor = (props) => {
    /*
            USAGE:
            <SelectColor func={SelectColorPress.bind(this)} />

            const SelectColorPress = (value) => {                
            }

            */
    const [color, setColor] = useState('#000000');
    const [showOpen, setShowOpen] = useState(0);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = sk;

    useEffect(() => {
        setColor(props.default);

        return () => {
        };

    }, []);


    const Press = (item) => {
        setColor(item);
        setShowOpen(false);
        func(item);
    }

    return (
        <div style={{ ...styles.BlockLeft }}>
            <Button onClick={() => setShowOpen(true)} style={{ ...styles.Button, width: 180, backgroundColor: global.theme_light_gray, color: global.theme_black, textTransform: 'none', border: '1px solid ' + global.theme_dark_gray }}>
                <div style={{ ...styles.Block, width: 40 }}>
                    <FontAwesomeIcon style={{ width: 30, height: 30, color: color, marginRight: 10 }} icon={faSquare} />
                </div>
                <div style={{ ...styles.BlockLeft, width: '60%' }}>
                    {props.label}
                </div>
                <div style={{ width: 20 }}>
                    <FontAwesomeIcon style={{ marginLeft: 10, width: 16, height: 16, color: global.theme_light }} icon={faChevronRight} />
                </div>
            </Button>
            {showOpen == true ?
                <Dialog open={true} maxWidth={'lg'} onBackdropClick={() => Press(true)}>
                    <div style={{ ...styles.BlockRow, width: 400, backgroundColor: global.theme_dark, height: 60, justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ width: '100%', paddingLeft: 10 }}>
                            <p style={{ ...styles.TextLarge, color: global.theme_white }}>{lang.choose_color}</p>
                        </div>
                        <div style={{ width: 50 }}>
                            <IconButton onClick={() => setShowOpen(false)} style={{ ...styles.BlockRound, width: 36, height: 36, backgroundColor: global.theme_light, alignSelf: 'flex-end' }}>
                                <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_white }} icon={faXmark} />
                            </IconButton>
                        </div>
                    </div>
                    <div style={{ ...styles.BlockRow, width: 400, flexWrap: 'wrap' }}>
                        {global.colors.map(item => (
                            <IconButton onClick={() => Press(item.color)} style={{ backgroundColor: item.color, width: 40, height: 40, borderRadius: 0, border: '1px solid black', borderRadius: 20, margin: 5 }}>

                            </IconButton>
                        ))}
                    </div>
                </Dialog>
                : null}
        </div >
    );
}

export const ShowOK = (props) => {
    /*
            USAGE:
            const [showOK, setShowOK] = useState(false);
            setShowOK(true);

            {showOK == true ?
                <ShowOK func={ShowOKPress.bind(this)} />
                : null}

            const ShowOKPress = (value) => {                
                setShowOK(false);
            }

            */

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = sk;

    useEffect(() => {
        const timer = setTimeout(() => {
            Press(false);
        }, 1000);

        return () => {
        };

    }, []);

    const Press = (item) => {
        func(item);
    }

    return (
        <Backdrop sx={{ color: '#000000', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true} onClick={null} style={{ backgroundColor: '#00000000' }}>
            <Paper elevation={3} style={{ ...styles.Block, marginLeft: global.menu_width - 40, width: 80, height: 80, backgroundColor: global.theme_white, borderRadius: 40 }}>
                <FontAwesomeIcon style={{ width: 40, height: 40, color: global.theme_green }} icon={faCheck} />
            </Paper>
        </Backdrop>
    );
}


export const CustomerSearch = (props) => {
    /*
            Hladat zákazníka
 
            USAGE:
            <CustomerSearch func={CustomerSearchPress.bind(this)} />
 
            const CustomerSearchPress = (value) => {
            }
 
    */
    const [isBusy, setBusy] = useState(false);

    const [search, setSearch] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [items, setItems] = React.useState([]);
    const [err, setErr] = React.useState('');


    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const navigate = useNavigate();
    const location = useLocation();


    let { func } = props;
    var lang = sk;

    const BarHeight = 70;

    useEffect(() => {

        return () => {
        };

    }, []);

    const Press = (items, str) => {
        func(items, str);
    }

    const db_search = async (txt, _quick) => {
        // SEARCH DURING TYPING
        setLoading(true);
        try {
            const response = await fetch(
                global.db_url + 'customer_searching', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    company_id: props.companyID,
                    search: txt,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setItems(json.items);
                if (_quick == false) {
                    Press(json.arr, txt);
                }
            }
            setLoading(false);
        } catch (error) {
            Debug(error);
            setLoading(false);
        }

    }


    const SearchItem = (value) => {
        // zobrazenie vybratého produktu
        if (value.id == undefined) {
            // jedna sa o hladany výraz
            if (search.length > 2) {
                // zobraziť výsledok

                var txt = value.trim();
                txt = txt.replaceAll('/', '');
                txt = txt.replaceAll('+', '');
                txt = txt.replaceAll('#', '');
                txt = txt.replaceAll(' ', '+');

                db_search(txt, false);
            }
        } else {
            // bol označený konkrétny produkt
            // zobraziť produkt
            Press([value.id], search);
        }
    }

    const Search = (txt_) => {
        var txt = txt_.trim();
        setSearch(txt);
        if (txt.length > 2) {
            var search = txt.trim();
            search = search.replaceAll('/', '');
            search = search.replaceAll('+', '');
            search = search.replaceAll('#', '');
            search = search.replaceAll(' ', '+');

            setErr('');
            db_search(search, true);
        }
    }

    return (

        <div style={{ ...styles.Block, justifyContent: 'flex-end', alignItems: 'center' }}>
            <Autocomplete
                freeSolo
                disableClearable
                style={{ width: '100%', maxWidth: 700, backgroundColor: global.theme_gray }}
                options={items}
                getOptionLabel={(option) => option.keys ? option.keys : ""}
                loading={loading}
                size={'small'}
                onChange={(event, newValue) => { SearchItem(newValue); }}
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        // Prevent's default 'Enter' behavior.
                        if (search.length < 3) {
                            event.defaultMuiPrevented = true;
                            setErr(lang.search_error);
                        } else {
                            setErr('');
                            SearchItem(search);
                        }

                    }
                }}
                renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        <p style={{ ...styles.TextTiny }}>
                            {option.name} {option.surname} - ({option.town})
                        </p>
                    </Box>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={err == '' ? lang.search : err}
                        onInput={e => Search(e.target.value)}
                        error={err == '' ? false : true}
                        style={{ backgroundColor: global.theme_white }}
                        InputProps={{
                            ...params.InputProps,
                            type: 'search',
                            startAdornment: <InputAdornment position="start"><FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_medium_gray }} icon={faSearch} /></InputAdornment>,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />
        </div>
    )
}


export const GetSlovom = (value) => {
    // vstup 0.0, 123.50, ....
    // vráti slovom od  0 - 999 000
    // aj centy 
    var numbers_1 = ['nula', 'jeden', 'dva', 'tri', 'štyri', 'päť', 'šesť', 'sedem', 'osem', 'deväť'];
    var numbers_2 = ['nula', 'jeden', 'dve', 'tri', 'štyri', 'päť', 'šesť', 'sedem', 'osem', 'deväť'];
    var numbers_3 = ['desať', 'jedennásť', 'dvanásť', 'trinásť', 'štrnásť', 'pätnásť', 'šestnásť', 'sedemnásť', 'osemnásť', 'devätnásť'];
    var numbers_4 = ['', 'desať', 'dsať', 'dsať', 'dsať', 'desiat', 'desiat', 'desiat', 'desiat', 'desiat'];

    var str_ = value.toString().replaceAll(',', '.');
    var arr = str_.split('.');

    var result = '';
    var result2 = '';
    if (arr.length > 0) {
        var str = arr[0];
        var eur = parseInt(str);

        var n = 0;

        while (n < str.length) {
            var chr = str.charAt(str.length - 1 - n);
            var val = parseInt(chr);
            if (n == 0) {
                // jdnotky 1 - 9
                if (val > 0) {
                    result = numbers_1[val];
                }
            }
            if (n == 1) {
                // desiatky
                if (val == 1) {
                    // 10 - 19
                    var sub_val = parseInt(str.charAt(str.length - n));
                    result = numbers_3[sub_val];
                } else {
                    // 20 - 99
                    if (val > 1) {
                        result = numbers_1[val] + numbers_4[val] + result;
                    }
                }
            }
            if (n == 2) {
                // stovky 100 - 900
                if (val > 0) {
                    result = numbers_2[val] + 'sto' + result;
                }
            }
            // ------------------------------------------------------------
            // 1 000 - 100 000
            // ------------------------------------------------------------
            if (n == 3) {
                // tisíce 1 - 9
                if (val > 0) {
                    result2 = numbers_2[val];
                }
            }
            if (n == 4) {
                // desaťtisíce 10 000 - 90 000
                if (val == 1) {
                    // 10 - 19
                    var sub_val = parseInt(str.charAt(str.length - n));
                    result2 = numbers_3[sub_val];
                } else {
                    // 20 - 99
                    if (val > 1) {
                        result2 = numbers_1[val] + numbers_4[val] + result2;
                    }
                }
            }
            if (n == 5) {
                // stotisíce 100 000 - 900 000
                if (val > 0) {
                    result2 = numbers_2[val] + 'sto' + result2;
                }
            }
            n++;
        }
    }
    if (result2 != '') {
        result2 = result2 + 'tisíc';
    }
    /* **********************************************************************
        CENTY
    ********************************************************************** */
    var centy = '';
    if (arr.length > 1) {
        var str = arr[1];

        if (str.length == 1) {
            str = str + '0';
        }
        var cent = parseInt(str);

        var n = 0;
        while (n < str.length) {
            var chr = str.charAt(str.length - 1 - n);
            var val = parseInt(chr);

            if (n == 0) {
                // jdnotky 1 - 9
                if (val > 0) {
                    centy = numbers_1[val];
                }
            }
            if (n == 1) {
                // desiatky
                if (val == 1) {
                    // 10 - 19
                    var sub_val = parseInt(str.charAt(str.length - n));
                    centy = numbers_3[sub_val];
                } else {
                    // 20 - 99
                    if (val > 1) {
                        centy = numbers_1[val] + numbers_4[val] + centy;
                    }
                }
            }
            n++;
        }
    }
    result = result2 + result;
    if (result == '') {
        result = numbers_1[0];
    }

    var eur_text = 'eur';
    if (eur == 0) {
        eur_text = 'eur';
    }
    if (eur == 1) {
        eur_text = 'euro';
    }
    if (eur > 1 && eur < 5) {
        eur_text = 'eura';
    }
    if (eur > 4) {
        eur_text = 'eur';
    }
    result = result + ' ' + eur_text;

    if (centy != '') {
        var cent_text = 'cent';
        if (cent == 1) {
            cent_text = 'cent';
        }
        if (cent > 1 && cent < 5) {
            cent_text = 'centy';
        }
        if (cent > 4) {
            cent_text = 'centov';
        }
        result = result + ' a ' + centy + ' ' + cent_text;
    }
    return result;
}

export const AskDialog = (props) => {
    /*
            USAGE:
            <AskDialog label='' question='' text='' button='' func={AskDialogResult.bind(this)} />

            const AskDialogResult = (value) => {   
                if(value == true){
                }
            }

            */
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = sk;

    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = (typ) => {
        func(typ);
    }


    return (
        <Dialog open={true} maxWidth={'lg'}>
            <div style={{ ...styles.BlockRow, backgroundColor: global.theme_dark, height: 50, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '100%', paddingLeft: 10 }}>
                    <p style={{ ...styles.TextLarge, color: global.theme_white }}>{props.label}</p>
                </div>
                <div style={{ width: 50 }}>
                    <IconButton onClick={() => Press(false, false)} style={{ ...styles.BlockRound, width: 30, height: 30, backgroundColor: global.theme_light, alignSelf: 'flex-end' }}>
                        <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_white }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>

            <div style={{ ...styles.Block, width: 600, paddingTop: 30, paddingBottom: 30 }}>
                <p style={{ ...styles.TextNormal, fontWeight: 'bold' }}>{props.question}</p>
                <p style={{ ...styles.TextTiny, marginTop: 10, textAlign: 'center' }}>{props.text}</p>
            </div>

            <div style={{ ...styles.Block, width: 600, height: 80 }}>
                <div style={{ ...styles.BlockRowRaw }}>
                    <Button onClick={() => Press(true)} style={{ ...styles.ButtonGreen, backgroundColor: global.theme_dark_red, margin: 10 }}>{props.button}</Button>
                    <Button onClick={() => Press(false)} style={{ ...styles.ButtonBack, margin: 10 }}>{lang.cancel}</Button>
                </div>
            </div>
        </Dialog >
    );
}


export const AskDelete = (props) => {
    /*
            USAGE:
            <AskDelete func={AskDeletePress.bind(this)} />

            const AskDeletePress = (value) => {                
            }

            */
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = sk;

    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = (typ) => {
        func(typ);
    }


    return (
        <Dialog open={true} maxWidth={'lg'}>
            <div style={{ ...styles.BlockRow, backgroundColor: global.theme_dark, height: 50, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '100%', paddingLeft: 10 }}>
                    <p style={{ ...styles.TextLarge, color: global.theme_white }}>{props.label}</p>
                </div>
                <div style={{ width: 50 }}>
                    <IconButton onClick={() => Press(false, false)} style={{ ...styles.BlockRound, width: 30, height: 30, backgroundColor: global.theme_light, alignSelf: 'flex-end' }}>
                        <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_white }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>

            <div style={{ ...styles.Block, width: 600, paddingTop: 30, paddingBottom: 30 }}>
                <p style={{ ...styles.TextNormal, fontWeight: 'bold' }}>{props.question}</p>
                <p style={{ ...styles.TextTiny, marginTop: 10, textAlign: 'center' }}>{props.text}</p>
            </div>

            <div style={{ ...styles.Block, width: 600, height: 80 }}>
                <div style={{ ...styles.BlockRowRaw }}>
                    <Button onClick={() => Press(true)} style={{ ...styles.ButtonGreen, backgroundColor: global.theme_dark_red, margin: 10 }}>{lang.delete}</Button>
                    <Button onClick={() => Press(false)} style={{ ...styles.ButtonBack, margin: 10 }}>{lang.cancel}</Button>
                </div>
            </div>
        </Dialog >
    );
}


export const AskLogout = (props) => {
    /*
            USAGE:
            <AskLogout func={AskLogoutResult.bind(this)} />

            const AskLogoutResult = (value) => {                
            }

            */
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = sk;

    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = (typ) => {
        func(typ);
    }


    return (
        <Dialog open={true} maxWidth={'lg'}>
            <div style={{ ...styles.BlockRow, backgroundColor: global.theme_dark, height: 50, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '100%', paddingLeft: 10 }}>
                    <p style={{ ...styles.TextLarge, color: global.theme_white }}>Odhlásenie</p>
                </div>
                <div style={{ width: 50 }}>
                    <IconButton onClick={() => Press(false, false)} style={{ ...styles.BlockRound, width: 30, height: 30, backgroundColor: global.theme_light, alignSelf: 'flex-end' }}>
                        <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_white }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>

            <div style={{ ...styles.Block, width: 600, paddingTop: 30, paddingBottom: 30 }}>
                <p style={{ ...styles.TextNormal, fontWeight: 'bold' }}>Chcete sa odhlásiť z aplikácie?</p>
            </div>

            <div style={{ ...styles.Block, width: 600, height: 80 }}>
                <div style={{ ...styles.BlockRowRaw }}>
                    <Button onClick={() => Press(true)} style={{ ...styles.ButtonGreen, backgroundColor: global.theme_dark_red, margin: 10 }}>Odhlásiť sa</Button>
                    <Button onClick={() => Press(false)} style={{ ...styles.ButtonBack, margin: 10 }}>{lang.cancel}</Button>
                </div>
            </div>
        </Dialog >
    );
}


function AddDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}


export function GetSplatnost(date, days) {
    return (GetDate(AddDays(date, days)));
}

export const PDFdialog = (props) => {
    /*
            USAGE:
            <PDFdialog func={PDFdialogResult.bind(this)} />

            const PDFdialogResult = (value) => {                
            }

            */
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = sk;

    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = (typ) => {
        func(typ);
    }


    return (
        <Dialog open={true} maxWidth={'lg'}>
            <div style={{ ...styles.BlockRow, backgroundColor: global.theme_dark, height: 50, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '100%', paddingLeft: 10 }}>
                    <p style={{ ...styles.TextLarge, color: global.theme_white }}>{props.label}</p>
                </div>
                <div style={{ width: 50 }}>
                    <IconButton onClick={() => Press(false, false)} style={{ ...styles.BlockRound, width: 30, height: 30, backgroundColor: global.theme_light, alignSelf: 'flex-end' }}>
                        <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_white }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>

            <div style={{ ...styles.Block, width: 600, paddingTop: 50, paddingBottom: 50 }}>
                <p style={{ ...styles.TextNormal, fontWeight: 'bold' }}>{props.question}</p>
                <p style={{ ...styles.TextTiny, marginTop: 10, textAlign: 'center' }}>{props.text}</p>
                <div style={{ ...styles.BlockRowRaw }}>
                    <a href={global.pdf + props.filename} target="_blank" style={{ ...styles.BlockRowRaw, width: 240, height: 45, backgroundColor: global.theme_dark_green, alignItems: 'center', justifyContent: 'center', textDecoration: 'none', marginRight: 10 }}>
                        <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_white, marginRight: 10 }} icon={faPrint} />
                        <p style={{ ...styles.TextSmall, color: global.theme_white }}>Zobraziť PDF súbor</p>
                    </a>
                    <a href={global.pdf + props.filename} download='faktura' target="_blank" style={{ ...styles.BlockRowRaw, width: 240, height: 45, backgroundColor: global.theme_dark_green, alignItems: 'center', justifyContent: 'center', textDecoration: 'none', marginLeft: 10 }}>
                        <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_white, marginRight: 10 }} icon={faFloppyDisk} />
                        <p style={{ ...styles.TextSmall, color: global.theme_white }}>Stiahnúť PDF</p>
                    </a>
                </div>
            </div>

            {/*
            <div style={{ ...styles.Block, width: 600, height: 80 }}>
                <div style={{ ...styles.BlockRowRaw }}>
                    <Button onClick={() => Press(true)} style={{ ...styles.ButtonGreen, backgroundColor: global.theme_dark_red, margin: 10 }}>{lang.delete}</Button>
                    <Button onClick={() => Press(false)} style={{ ...styles.ButtonBack, margin: 10 }}>{lang.cancel}</Button>
                </div>
            </div>
            */}
        </Dialog >
    );
}

export const FormatIban = (iban_) => {
    var iban = iban_.replaceAll(' ', '');
    var result = iban.slice(0, 4) + ' ' + iban.slice(4, 8) + ' ' + iban.slice(8, 12) + ' ' + iban.slice(12, 16) + ' ' + iban.slice(16, 20) + ' ' + iban.slice(20, 24);
    return result;
}


export const FakturaSend = (props) => {
    /*
            USAGE:
            <FakturaSend item={item}  func={FakturaSendResult.bind(this)} />

            const FakturaSendResult = (value) => {
                Debug(value)
            }
            */
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [showOk, setShowOK] = useState(false);
    const [text, setText] = useState('');
    const [email, setEmail] = useState('');
    const [email2, setEmail2] = useState('');
    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = sk;

    useEffect(() => {
        setEmail(props.item.email);
        setText('Vážený zákazník, v prílohe Vám posielame faktúru č.' + props.item.number + ' za dodané služby. Datadream s.r.o.');
        return () => {
        };

    }, []);


    const Press = (typ) => {
        func(typ);
    }


    const db_send = async () => {

        if (isBusy == false) {

            setBusy(true);
            setError('');
            try {
                const response = await fetch(
                    global.db_url + 'faktura_send', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        id: props.item.id,
                        email: email,
                        email2: email2,
                        text: text,
                        typ: props.typ
                    })
                })

                const json = await response.json();
                console.log(json);
                if (json.ok > 0) {
                    if (json.sended == true) {
                        setShowOK(true);
                    } else {
                        setError(lang.send_error);
                    }
                } else {
                    setError(lang.send_error);
                }
                setBusy(false);
            } catch (error) {
                Debug(error);
                setBusy(false);
            }
        }
    }

    const Save = () => {
        db_send();
    }

    return (
        <Dialog open={true} fullScreen={isSmall ? true : false} maxWidth={'lg'}>
            <div style={{ ...styles.BlockRow, backgroundColor: global.theme_dark, height: 50, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '100%', paddingLeft: 10 }}>
                    <p style={{ ...styles.TextLarge, color: global.theme_white }}>{'Odoslať faktúru'}</p>
                </div>
                <div style={{ width: 50 }}>
                    <IconButton onClick={() => Press(false)} style={{ ...styles.BlockRound, width: 30, height: 30, backgroundColor: global.theme_light, alignSelf: 'flex-end' }}>
                        <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_white }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>

            {showOk == false ?
                <div style={{ ...styles.Block, paddingTop: 20, width: isSmall ? '100%' : 600 }}>
                    <div style={{ ...styles.BlockLeft, width: '90%' }}>
                        <div style={{ ...styles.BlockRow }}>
                            <div style={{ ...styles.BlockLeft, marginRight: 5 }}>
                                <p style={{ ...styles.TextTiny, marginBottom: 5, fontWeight: 'bold' }}>e-mail</p>
                                <TextField
                                    variant="outlined"
                                    label={''}
                                    value={email}
                                    size={'small'}
                                    style={{ width: '100%' }}
                                    inputProps={{ maxLength: 1000 }}
                                    onInput={e => setEmail(e.target.value)}
                                />
                            </div>
                            <div style={{ ...styles.BlockLeft, marginLeft: 5 }}>
                                <p style={{ ...styles.TextTiny, marginBottom: 5, fontWeight: 'bold' }}>e-mail - poslať kópiu</p>
                                <TextField
                                    variant="outlined"
                                    label={''}
                                    value={email2}
                                    size={'small'}
                                    style={{ width: '100%' }}
                                    inputProps={{ maxLength: 1000 }}
                                    onInput={e => setEmail2(e.target.value)}
                                />
                            </div>
                        </div>
                        <p style={{ ...styles.TextTiny, marginTop: 40, marginBottom: 5, fontWeight: 'bold' }}>Text správy</p>
                        <TextField
                            rows={4}
                            multiline={true}
                            variant="outlined"
                            label={''}
                            value={text}
                            size={'small'}
                            style={{ width: '100%' }}
                            inputProps={{ maxLength: 1000 }}
                            onInput={e => setText(e.target.value)}
                        />
                    </div>
                </div>
                :
                <div style={{ ...styles.Block, paddingTop: 20, width: isSmall ? '100%' : 600 }}>
                    <div style={{ ...styles.Block, width: '95%', marginTop: 50, marginBottom: 30 }}>
                        <div style={{ ...styles.BlockRound, width: 50, height: 50 }}>
                            <FontAwesomeIcon style={{ width: 35, height: 35, color: global.theme_white }} icon={faCheck} />
                        </div>
                        <p style={{ ...styles.TextLarge, marginTop: 30, textAlign: 'center' }}>{'Faktúra bola úspešne odoslaná'}</p>
                        <div style={{ ...styles.Block, width: '50%', paddingTop: 40 }}>
                            <Button onClick={() => Press(true)} style={{ ...styles.ButtonBack, margin: 10 }}>{lang.close}</Button>
                        </div>
                    </div>
                </div>
            }

            {showOk == false ?
                <div style={{ ...styles.Block }}>
                    <div style={{ ...styles.Block }}>
                        <p style={{ ...styles.TextSmall, textAlign: 'center' }}>{lang.order_reklamacia_send_text}</p>
                    </div>
                    {error != '' ?
                        <div style={{ ...styles.Block, paddingTop: 10 }}>
                            <p style={{ ...styles.TextSmall, textAlign: 'center', color: global.theme_red, fontWeight: 'bold' }}>{error}</p>
                        </div>
                        : null}
                    <div style={{ ...isSmall ? styles.Block : styles.BlockRow, width: undefined, paddingTop: 10, paddingBottom: 10 }}>
                        <Button disabled={text.trim() == '' ? true : false} onClick={() => Save()} style={{ ...styles.ButtonGreen, backgroundColor: text == '' ? global.theme_light_green : global.theme_green, margin: 10 }}>{lang.send}</Button>
                        <Button onClick={() => Press(false, false)} style={{ ...styles.ButtonBack, margin: 10 }}>{lang.cancel}</Button>
                    </div>
                </div>
                : null}

            {isBusy == true ?
                <Loading center={true}></Loading>
                : null}
        </Dialog >
    );
}



