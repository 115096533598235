import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { sk } from './globals.js';
import { Paper } from '@mui/material';
import { Debug, Menu, MenuTop, LoadLogin, LoadUser, ExpireDialog, UserData, SaveUser, CounterChange, WelcomeDialog, Subscription, FormatIban } from './items.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme2-6.css";
import { styles } from './styles.js';
import { faFileLines  } from '@fortawesome/free-solid-svg-icons';
import { style } from '@mui/system';
import { Company } from './company.js';
import { Odberatelia } from './odberatelia.js';
import { Faktury } from './faktury.js';




export const Main = (props) => {
    const navigate = useNavigate();
    const [isBusy, setBusy] = useState(false);
    const [isLoading, setLoading] = useState(true);

    const [web, setWeb] = useState([]);

    // prihlásený užívazeľ
    const [logged, setLogged] = useState(false);
    const [user, setUser] = useState(null);
    const [userTyp, setUserTyp] = useState(null);
    const [label, setLabel] = useState('');
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [showUser, setShowUser] = useState(false);
    const [company, setCompany] = useState([]);

    const [showTest, setShowTest] = useState(false);

    const [bodyID, setBodyID] = useState(0);
    const [year, setYear] = useState('2022');

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    var lang = sk;
    var timer = null;
    const homeRef = useRef(null)

    const logo = require('./react/app/logo.png');


    useEffect(() => {

        // RELOAD PAGE - AK SA ZMENI VELKOST OKNA        
        function handleResize() {
            clearTimeout(timer);
            timer = setTimeout(() => {
                setWidth(window.innerWidth);
                setHeight(window.innerHeight);
            }, 500);
        }
        window.addEventListener('resize', handleResize);

        //localStorage.removeItem('admin_login');

        var now = new Date();
        var year_ = now.getFullYear();
        setYear(year_);

        // KONTROLA PRIHLASENIA
        var data = LoadLogin();
        var user_id = 0;

        if (data != null) {
            if (data.logged == true) {
                setLogged(true);
                var user = LoadUser();
                user_id = user.id;
                setUser(user);
                setName(user.name);
                setSurname(user.surname);
                setUserTyp(user.typ);
                db_web(user.company_id);
            } else {
                navigate('/login');
            }
        }

        setLabel(lang.admin);

        return () => {
        };

    }, []);

    const db_web = async (company_id) => {
        setLoading(true);

        try {
            const response = await fetch(
                global.db_url + 'web', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    company_id: company_id
                })
            })

            const json = await response.json();

            if (json.ok > 0) {

            }
            setLoading(false);

        } catch (error) {
            Debug(error);
            setLoading(false);
        }
    }

    const MenuPress = (id, children_id, item) => {
        if (id != 0) {
            var menu_id = parseInt(id * 1000) + parseInt(children_id);

            var menu = global.menuitems.find(x => parseInt(x.id) == id);
            var submenu = menu.children.find(x => parseInt(x.id) == children_id)

            setLabel(submenu.label);
            setBodyID(menu_id);
        } else {
            setLabel(lang.admin);
            setBodyID(0);
            //db_counters(companyID);
        }
    }


    const MenuTopPress = (typ, children_id, item) => {
        if (typ == 999) {
            // ADMINISTRATOR - UDAJE
            setShowUser(true);
        }
        else {
            // stlacena menu
            MenuPress(typ, children_id, item);
        }
    }

    const UserDataPress = (typ, data) => {
        if (typ == false) {
            setShowUser(false);
        }
        if (typ == true) {
            setUser(data);
            setName(data.name);
            setSurname(data.surname);
            setShowUser(false);
            SaveUser(data);
        }

    }


    return (
        <div>
            <div style={{ ...isSmall ? styles.Block : styles.BlockRow, backgroundColor: global.theme_back, width: isSmall ? undefined : width, height: isSmall ? undefined : height }}>
                <Menu company={company} height={height} func={MenuPress.bind(this)} />
                <div style={{ ...styles.Block }}>
                    <MenuTop company={company} height={height} label={label} name={name} surname={surname} search={false} func={MenuTopPress.bind(this)} />

                    {/* BODY */}
                    <div style={{ ...styles.BlockCenter, width: '98%', height: isSmall ? undefined : height - 95, overflowY: 'scroll' }}>


                        {/* MAIN BODY - HOME */}
                        {bodyID == 0 ?
                            <div style={{ ...styles.Block, paddingTop: 0, paddingBottom: 0 }}>
                                <div style={{ ...isSmall ? styles.Block : styles.BlockRowRaw, paddingTop: 20 }}>
                                    <Paper onClick={() => MenuPress(1, 1)} elevation={0} style={{ ...styles.ButtonDashboard, margin: 20, backgroundColor: global.theme_white, cursor: 'pointer' }} >
                                        <FontAwesomeIcon style={{ width: 22, height: 22, color: global.theme_light }} icon={faFileLines} />
                                        <p style={{ ...styles.TextSmall, color: global.theme_dark, marginTop: 10 }}>{'Faktúry'}</p>
                                    </Paper>
                                </div>
                                
                            </div>
                            : null}

                        {/* SELECTED BY MENU */}
                        {bodyID == 1001 ?
                            <Faktury user_typ={userTyp} />
                            : null}
                        {bodyID == 1002 ?
                            <Odberatelia user_typ={userTyp} />
                            : null}
                        {bodyID == 1003 ?
                            <Company user_typ={userTyp} />
                            : null}
                    </div>
                    <div style={{ ...isSmall ? style.Block : styles.BlockLeft, height: 25 }}>
                        <a href='https://datadream.sk' style={{ margin: 0, color: global.theme_dark_gray, fontSize: global.font_tiny, textDecoration: 'none', marginLeft: 10 }}>© {lang.copyright} {year} - {global.creator}</a>
                    </div>
                </div>

                {showUser == true ?
                    <UserData user={user} func={UserDataPress.bind(this)} />
                    : null}

            </div >


        </div >
    )
};

