import React, { useEffect, useState } from 'react';

import './App.css';
import { Loading } from './items';
import { Backdrop, Button, IconButton, Paper, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCirclePlus, faImage, faList, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { useParams } from "react-router-dom";

import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

export const Photos = (props) => {
    /*
    USAGE: 
            UPLOAD fotografie + cropping
            Veľkosť dialogového okna 600 x 600 px

            Volanie:
            https://app.datadream.sk/photos/typ/width/height/full/max_size

            príklad:
            https://app.datadream.sk/photos/0/600/600/0/600

            --------------------------------------------------------------------------------------------------------------------
            full        => ak je TRUE - nastaví sa orez na plnú veľkosť fotografie - neproporčný orez
            max_size    => fotografia po zmensení (rozmer najdlhšej strany)
            width       => požadovaná šírka fotografie
            height      => požadovaná výška fotografie
            typ         => typy, ktore oznacuju cestu, do ktorého adresara budú fotografie nahraté na servery
                            0 - faktúry - pečiatka
                            1 - 
                            2 - 
                            3 - 
                            4 - 
            --------------------------------------------------------------------------------------------------------------------

            Vysledok je zapísaný do DB - uploads

    */

    const logo = require('./react/app/no_image.jpg');
    let params = useParams();

    const [isBusy, setBusy] = useState(false);
    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [category, setCategory] = useState(0);
    const [filename, setFilename] = useState('');
    const [note, setNote] = useState('');
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [crop, setCrop] = useState(params.full == true ?
        {
            unit: '%',
            x: 0,
            y: 0,
            width: 100,
            height: 100
        }
        :
        {
            unit: '%',
            x: 25,
            y: 25,
            width: 50,
            height: 50
        })


    var lang = photos_sk;

    const imageMul = 1.5; // nasobenie width fotografie (ovplyvňuje veľkosť okna)
    const imageSize = 350;
    const dialogWidth = 600;
    const dialogHeight = 600;
    const titleHeight = 40;
    const noteHeight = 80;
    const bottomHeight = 80;

    const image_upload = async () => {

        setBusy(true);
        var coef = 0;
        if (height >= width) {
            coef = height / imageSize;
        } else {
            coef = width / (imageSize * imageMul)
        }

        const data = new FormData();
        data.append('typ', params.typ);
        data.append('category', category);
        data.append('note', note);
        data.append('crop_x', crop.x);
        data.append('crop_y', crop.y);
        data.append('crop_w', crop.width);
        data.append('crop_h', crop.height);
        data.append('width', width);
        data.append('height', height);
        data.append('coef', coef);
        data.append('max_size', params.max_size);
        data.append('filename', filename);
        data.append("name", "image");
        data.append("file_attachment", selectedImage);
        try {
            const response = await fetch(
                'https://app.datadream.sk/app_datadream/' + 'upload_image', {
                method: 'POST',
                body: data
            }
            );
            const json = await response.json();
            setBusy(false);
            console.log(json);
            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            console.log(json);

        } catch (error) {
            setBusy(false);
            console.log(error);
        }
    };

    useEffect(() => {
    }, []);

    const ChangeCrop = (c) => {
        setCrop(c);
    }

    function PhotoSelect(e) {
        // FOTOGRAFIE - VYBER SUBOROV A ODOSLANIE NA SERVER        
        var file = e.target.files[0];
        var filename = file.name.toLowerCase();

        // ROZMERY FOTOGRAFIE
        var _URL = window.URL || window.webkitURL;
        var img_link = URL.createObjectURL(file);

        const img = new Image();
        var w = 0;
        var h = 0;
        img.onload = function () {
            GetSize(this.width, this.height);
            _URL.revokeObjectURL(img_link);
        };
        img.src = img_link;

        setSelectedImage(file);
        setFilename(filename);
    }

    function PhotoDelete() {
        setSelectedImage(null);
        if (params.full == true) {
            setCrop({
                unit: '%',
                x: 0,
                y: 0,
                width: 100,
                height: 100
            });
        } else {
            setCrop({
                unit: 'px',
                x: 25,
                y: 25,
                width: 50,
                height: 50
            });
        }
        setNote('');
    }

    const GetSize = (w, h) => {
        setWidth(w);
        setHeight(h);

        // VYPOCET NAJKRATSEJ DLZKY A NASTAVENIE CROPPINGU
        var min_size = h;
        if (h > w) {
            min_size = w;
        }

        if (h >= w) {
            var coef = h / imageSize;
        } else {
            var coef = w / (imageSize * imageMul);
        }
        min_size = (min_size / coef) - 20;

        if (params.full == true) {
            setCrop({
                unit: 'px',
                x: 0,
                y: 0,
                width: w / coef,
                height: h / coef
            });
        } else {
            setCrop({
                unit: 'px',
                x: 10,
                y: 10,
                width: min_size,
                height: min_size
            });
        }
    }

    const PhotoSave = () => {
        image_upload();
    }

    const ShowOKPress = () => {
        setShowOK(false);
        PhotoDelete();
    }

    const ShowErrorPress = () => {
        setShowError(false);
    }

    return (
        <div style={{ width: dialogWidth, height: dialogHeight, background: global.theme_back }}>

            {/* BODY */}
            <div style={{ ...styles.BlockCenter, backgroundColor: global.theme_back }}>
                <div style={{ ...styles.Block, height: titleHeight }}>
                    <p style={{ ...styles.TextSmall }}><b>{lang.photo_select_title}</b> <span style={{ ...styles.TextTiny }}>{params.full == true ? '' : '(' + lang.photo_min + ': ' + params.width + 'x' + params.height + ' px)'}</span></p>
                </div>
                {selectedImage != null && selectedImage != undefined ?
                    <div style={{ ...styles.Block, width: dialogWidth - 20, height: dialogHeight - 20 - titleHeight - noteHeight - bottomHeight }}>
                        <ReactCrop crop={crop} onChange={c => ChangeCrop(c)} aspect={params.full == true ? undefined : params.width / params.height} >
                            <img
                                src={URL.createObjectURL(selectedImage)}
                                style={{ maxWidth: '100%', width: height < width ? imageSize * imageMul : undefined, height: height >= width ? imageSize : undefined, objectFit: 'contain' }}
                                alt="Thumb"
                            />
                        </ReactCrop>
                    </div>
                    : <div style={{ ...styles.Block, height: dialogHeight - 22 - titleHeight - noteHeight - bottomHeight, width: dialogHeight - 20, border: '1px solid ' + global.theme_light_gray }}>
                        <img src={logo} style={{ width: '100%', height: '100%', maxWidth: 200, maxHeight: 200, marginTop: 10, objectFit: 'contain' }}></img>
                    </div>}
                <div style={{ ...styles.Block, width: dialogWidth, height: noteHeight }}>
                    <p style={{ ...styles.TextTiny, marginBottom: 5 }}>Poznámka</p>
                    <TextField
                        value={note}
                        onInput={e => setNote(e.target.value)}
                        size="small"
                        autoCapitalize="off"
                        autoComplete="off"
                        autoCorrect="off"
                        style={{ width: '95%' }}
                        placeholder={lang.label}
                        InputProps={{
                            style: { backgroundColor: global.theme_white, borderRadius: 20 },
                        }}
                        variant="outlined"
                    />

                </div>
                <div style={{ ...styles.Block, width: dialogWidth, paddingTop: 20, height: bottomHeight }}>
                    {selectedImage != null && selectedImage != undefined ?
                        // ULOZIT / VYMAZAŤ
                        <div style={{ ...styles.BlockRow }}>
                            <div style={{ ...styles.Block }}>
                                <Button onClick={() => PhotoDelete()} style={{ ...styles.ButtonRed, width: 180 }}>
                                    <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faXmarkCircle} />
                                    {lang.photo_delete}
                                </Button>
                            </div>
                            <div style={{ ...styles.Block }}>
                                <Button onClick={() => PhotoSave()} style={{ ...styles.ButtonDark, width: 180 }}>
                                    {lang.photo_save}
                                </Button>
                            </div>
                        </div>
                        :
                        // VYBRAT FOTOGRAFIU
                        <Button variant="contained" component="label" style={{ ...styles.ButtonDark, width: 220 }}>
                            <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faCirclePlus} />
                            {lang.photo_select}
                            <input onChange={(e) => PhotoSelect(e)} hidden accept="image/jpeg,image/png" type="file" />
                        </Button>
                    }
                </div>
            </div>

            {showOK == true ?
                <ShowPhotoOK center={true} func={ShowOKPress.bind(this)} />
                : null}

            {showError == true ?
                <ShowPhotoError center={true} text={lang.db_error} func={ShowErrorPress.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading center={true} ></Loading>
                : null}

        </div>

    );
}


export const ShowPhotoOK = (props) => {
    /*
            USAGE:
            const [showOK, setShowOK] = useState(false);
            setShowOK(true);

            {showOK == true ?
                <ShowOK func={ShowOKPress.bind(this)} />
                : null}

            const ShowOKPress = (value) => {                
                setShowOK(false);
            }

            */

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    let { func } = props;
    var lang = photos_sk;

    useEffect(() => {
        const timer = setTimeout(() => {
            Press(false);
        }, 1000);

        return () => {
        };

    }, []);

    const Press = (item) => {
        func(item);
    }

    return (
        <Backdrop sx={{ color: '#000000', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true} onClick={null} style={{ backgroundColor: '#00000000' }}>
            <Paper elevation={6} style={{ ...styles.Block, marginLeft: 0, width: 60, height: 60, backgroundColor: '#007700', borderRadius: 40 }}>
                <FontAwesomeIcon style={{ width: 40, height: 40, color: global.theme_white }} icon={faCheck} />
            </Paper>
        </Backdrop>
    );
}


export const ShowPhotoError = (props) => {
    /*
            USAGE:
            const [showError, setShowError] = useState(false);
            setShowError(true);

            {showError == true ?
                <ShowError text={'Chyba'} func={ShowErrorPress.bind(this)} />
                : null}

            const ShowErrorPress = (value) => {                
                setShowError(false);
            }

            */

    const [width, setWidth] = useState(window.innerWidth);

    let { func } = props;
    var lang = photos_sk;

    useEffect(() => {
        const timer = setTimeout(() => {
            Press(false);
        }, 4000);

        return () => {
        };

    }, []);

    const Press = (item) => {
        func(item);
    }

    return (
        <Backdrop sx={{ color: '#000000', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true} onClick={null} style={{ backgroundColor: '#00000000' }}>
            <Paper elevation={6} style={{ ...styles.Block, marginLeft: 0, width: 320, height: 130, backgroundColor: global.theme_black, borderRadius: 16 }}>
                <div style={{ ...styles.Block, width: 40, height: 40, borderRadius: 20, backgroundColor: global.theme_dark_red }}>
                    <FontAwesomeIcon style={{ width: 30, height: 30, color: global.theme_white }} icon={faXmark} />
                </div>
                <div style={{ ...styles.Block, width: '95%' }}>
                    <p style={{ ...styles.TextSmall, color: global.theme_white, marginTop: 15 }}>{'Pri nahrávaní nastala chyba! Skúste požiadavku opakovať'}</p>
                </div>
            </Paper>
        </Backdrop>
    );
}


export const photos_sk = {
    photo: 'Fotografia',
    photo_select_title: 'Výber a úprava fotografie',
    photo_select: 'Vybrať fotografiu',
    photo_delete: 'Vymazať',
    photo_save: 'Uložiť',
    photo_min: 'Požadovaná veľkosť',
    select: 'Vybrať',
}

export const styles = {
    Block: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%'
    },
    BlockPadding: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        paddingLeft: 10,
        paddingRight: 10
    },
    BlockRaw: {
        display: 'flex',
        //justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    BlockCenter: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%'
    },
    BlockCenterPadding: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'column',
        paddingLeft: 10,
        paddingRight: 10
    },
    BlockRow: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'row',
        width: '100%'
    },
    BlockRowRaw: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'row',
    },
    BlockLeft: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        width: '100%'
    },
    BlockRight: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        flexDirection: 'column',
        width: '100%'
    },
    BlockMaxLarge: {
        // BLOK S MAX.PREDNASTAVENOU SIRKOU
        display: 'flex',
        flexDirection: 'column',
        maxWidth: global.screen_max,
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    BlockMaxSmall: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 320,
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    BlockMenu: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'column',
        width: global.menu_width,
        backgroundColor: global.theme_dark_blue
    },
    BlockMenuTitle: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'column',
        width: global.menu_width,
        backgroundColor: '#B7CFFF'
    },
    BlockRound: {
        // BOX okrúhly pre faCheck
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        borderRadius: '50%',
        width: 40,
        height: 40,
        backgroundColor: global.theme_dark_red,
    },
    // ************************************************************************************************
    // BUTTONS
    // ************************************************************************************************
    Button: {
        color: global.theme_black,
        backgroundColor: global.theme_gray,
        fontSize: 16,
        width: 150,
        height: 36,
        textTransform: 'none',
        fontWeight: 'normal'
    },
    ButtonLight: {
        color: global.theme_white,
        background: global.theme_medium,
        fontSize: 16,
        width: 150,
        height: 36,
        borderRadius: 18,
        textTransform: 'none',
        fontWeight: '400'
    },
    ButtonDark: {
        color: global.theme_white,
        background: '#3D5273',
        fontSize: 16,
        width: 150,
        height: 36,
        borderRadius: 18,
        textTransform: 'none',
        fontWeight: '400'
    },
    ButtonDarkSmall: {
        color: global.theme_white,
        background: global.theme_dark,
        fontSize: 14,
        width: 150,
        height: 36,
        borderRadius: 18,
        textTransform: 'none',
        fontWeight: '400'
    },
    ButtonGreen: {
        color: global.theme_white,
        background: global.theme_darker_green,
        fontSize: 16,
        width: 150,
        height: 36,
        borderRadius: 18,
        textTransform: 'none',
        fontWeight: '400'
    },
    ButtonRed: {
        color: global.theme_white,
        background: global.theme_dark_red,
        fontSize: 16,
        width: 150,
        height: 36,
        borderRadius: 18,
        textTransform: 'none',
        fontWeight: '400'
    },
    ButtonRadius: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: global.theme_white,
        background: global.theme_dark_red,
        fontSize: 16,
        width: 36,
        height: 36,
        borderRadius: 18,
        textTransform: 'none',
        fontWeight: '400'
    },
    ButtonRedSmall: {
        color: global.theme_white,
        background: global.theme_dark_red,
        fontSize: 14,
        width: 150,
        height: 36,
        borderRadius: 18,
        textTransform: 'none',
        fontWeight: '400'
    },
    ButtonLink: {
        color: global.theme_black,
        fontSize: 14,
        width: 150,
        height: 20,
        textTransform: 'none',
        fontWeight: 'normal'
    },
    ButtonFiles: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: global.theme_black,
        fontSize: 13,
        width: 75,
        height: 25,
        textTransform: 'none',
        fontWeight: 'normal',
    },
    ButtonDelete: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: global.theme_gray,
        color: global.theme_black,
        fontSize: 13,
        width: 120,
        height: 28,
        borderRadius: 14,
        textTransform: 'none',
        fontWeight: 'normal'
    },
    ButtonMenu: {
        color: global.theme_gray,
        backgroundColor: '#00000000',
        textTransform: 'none',
        fontWeight: 'normal',
        justifyContent: 'left',
        margin: 0,
        padding: 0,
        paddingLeft: 20,
        width: global.menu_width - 20,
        height: 34,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderTopRightRadius: 17,
        borderBottomRightRadius: 17,
        fontSize: 12,
    },
    ButtonSettings: {
        marginBottom: 10,
        color: global.theme_medium_gray,
        backgroundColor: '#00000000',
        fontSize: 16,
        width: 195,
        height: 40,
        textTransform: 'none',
        fontWeight: 'normal',
        borderTopLeftRadius: 20,
        borderBottomLeftRadius: 20,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        marginLeft: 5
    },
    ButtonSettingsBig: {
        marginBottom: 0,
        color: global.theme_medium_gray,
        backgroundColor: '#00000000',
        fontSize: 16,
        width: 245,
        height: 60,
        textTransform: 'none',
        fontWeight: 'normal',
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        marginLeft: 5
    },
    ButtonSettingsMedium: {
        marginBottom: 0,
        color: global.theme_medium_gray,
        backgroundColor: '#00000000',
        fontSize: 16,
        width: 245,
        height: 50,
        textTransform: 'none',
        fontWeight: 'normal',
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        marginLeft: 5
    },
    ButtonFilter: {
        color: global.theme_white,
        background: global.theme_dark,
        fontSize: 14,
        width: 160,
        height: 28,
        borderRadius: 15,
        textTransform: 'none',
        fontWeight: '300',
        letterSpacing: 0.5
    },
    ButtonSelect: {
        color: global.theme_white,
        background: global.theme_blue,
        fontSize: 14,
        width: 160,
        height: 28,
        borderRadius: 15,
        textTransform: 'none',
        fontWeight: '300',
        letterSpacing: 0.5
    },


    Label: {
        fontSize: global.font_label,
        margin: 0,
        marginTop: 0,
        marginBottom: 0,
        color: global.theme_black,
        fontWeight: 'bold'
    },
    TextXXLarge: {
        margin: 0,
        marginTop: 0,
        marginBottom: 0,
        fontSize: global.font_xxlarge,
        color: global.theme_black,
    },
    TextXLarge: {
        margin: 0,
        marginTop: 0,
        marginBottom: 0,
        fontSize: global.font_xlarge,
        color: global.theme_black,
    },
    TextLarge: {
        margin: 0,
        marginTop: 0,
        marginBottom: 0,
        fontSize: global.font_large,
        color: global.theme_black,
    },
    TextNormal: {
        margin: 0,
        marginTop: 0,
        marginBottom: 0,
        fontSize: global.font_normal,
        color: global.theme_black,
    },
    TextMenu: {
        margin: 0,
        marginTop: 0,
        marginBottom: 0,
        fontSize: 17,
        color: global.theme_black,
    },
    TextInput: {
        margin: 0,
        marginTop: 0,
        marginBottom: 3,
        fontSize: 14,
        color: global.theme_dark_gray,
        fontWeight: 'bold'
    },
    TextSmall: {
        margin: 0,
        marginTop: 0,
        marginBottom: 0,
        fontSize: global.font_small,
        color: global.theme_black,
    },
    TextTiny: {
        margin: 0,
        marginTop: 0,
        marginBottom: 0,
        fontSize: global.font_tiny,
        color: global.theme_black,
    },
    TextXTiny: {
        margin: 0,
        marginTop: 0,
        marginBottom: 0,
        fontSize: global.font_xtiny,
        color: global.theme_black,
    },
    TextXXTiny: {
        margin: 0,
        marginTop: 0,
        marginBottom: 0,
        fontSize: global.font_xxtiny,
        color: global.theme_black,
    },
    TextTitle: {
        margin: 0,
        marginTop: 0,
        marginBottom: 0,
        fontSize: global.font_xlarge,
        color: global.theme_black,
    },
    TextTinyMobile: {
        margin: 0,
        marginTop: 0,
        marginBottom: 0,
        fontSize: global.font_tiny,
        color: global.theme_black,
    },
    ButtonPin: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 80,
        height: 80,
        borderRadius: '50%',
        border: '1px solid #FFFFFF',
        fontSize: global.font_xxlarge,
        color: global.theme_white,
        backgroundColor: global.theme_dark,
        cursor: 'pointer'
    },
    TextPin: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 16,
        height: 16,
        borderRadius: '50%',
        border: '1px solid #FFFFFF',
        fontSize: global.font_xxlarge,
        color: global.theme_white,
        backgroundColor: global.theme_dark
    },

    BorderTop:
    {
        borderTop: '1px solid ' + global.theme_gray
    },
    BorderBottom:
    {
        borderBottom: '1px solid ' + global.theme_gray
    },
    BorderTopBold:
    {
        borderTop: '3px solid ' + global.theme_gray
    },
    BorderBottomBold:
    {
        borderBottom: '3px solid ' + global.theme_gray
    },
    Border:
    {
        border: '1px solid ' + global.theme_gray
    },
    MenuLine:
    {
        marginTop: 0,
        marginBottom: 10,
        height: 1,
        justifySelf: 'center',
        alignSelf: 'center',
        width: global.menu_width - 70,
        borderTop: '1px solid ' + global.theme_dark_gray
    },
    TextDialogLabel: {
        fontWeight: '500',
        margin: 0,
        marginTop: 0,
        marginBottom: 0,
        fontSize: 18,
        letterSpacing: 0.2,
        color: '#000000',
    },
    TextDialogSubLabel: {
        fontWeight: '300',
        margin: 0,
        marginTop: 0,
        marginBottom: 0,
        fontSize: 14,
        letterSpacing: 0.5,
        color: '#333333',
    },
};